<template>
  <vx-card>
    <div style="position:relative;">
      <vs-tabs class="supplier-detail" :value="value">
        <vs-tab label="Supplier Details">
          <div>
            <SupplierDetails :supplierId="supplierId" />
          </div>
        </vs-tab>
        <vs-tab label="Products">
          <div>
            <ProductList :supplierId="supplierId" />
          </div>
        </vs-tab>
        <vs-tab label="Orders">
          <div>
            <OrderList :supplierId="supplierId" @viewOrderDetails="viewOrderDetails" />
          </div>
        </vs-tab>
        <vs-tab label="Clinics">
          <div>
            <ClinicListModule :canViewDetail="true" viewRoute="SuperAdminClinicDetail" :supplierId="supplierId" />
          </div>
        </vs-tab>
      </vs-tabs>

    </div>
  </vx-card>
</template>

<script>
import SupplierDetails from "../../../components/WebStore/suppliers/SupplierDetailsV2";
import ProductList from "../../../components/WebStore/suppliers/ProductList";
import OrderList from "../../../components/WebStore/suppliers/OrderList";
import ClinicListModule from "../../../components/WebStore/suppliers/ClinicListModule";
import { mapActions } from "vuex";

export default {
  name: 'View-Supplier',
  components: {
    ClinicListModule,
    SupplierDetails,
    ProductList,
    OrderList
  },
  data: () => ({
    supplierId: '',
    value: 0,
  }),
  methods: {
    viewOrderDetails(data) {
      this.$router.push({
        name: "superAdminStoreViewSuppliersOrderDetail",
        params: { orderId: data.orderId, supplierId: data.supplierId }
      });
    },
  },
  async created() {
    this.supplierId = this.$route.params.supplierId;
    if (this.$route.params.tab) this.value = this.$route.params.tab
  }
};
</script>
