<template>
  <vs-card style="padding-left: 10px">
    <template slot="header">
      <div class="flex flex-no-wrap w-full justify-end items-center gap-2">
        <vs-button @click="openAccountLevelLogs" class="bg-green-light text-primary hover:text-primary" type="filled">
          View Logs
        </vs-button>
        <vs-button @click="openCreatePopUp">
          Create Account Level
        </vs-button>
      </div>
    </template>
    <vs-alert v-if="!keyAccountId || keyAccountId == ''" active="true" color="warning" class="mb-5" icon="info">
      No account Level is set as Key Account, please mark one as Key Account.
    </vs-alert>
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :data="accountLevels"
        :noDataText="noDataText"
      >
        <template slot="thead">
          <vs-th sort-key="name">Name</vs-th>
          <vs-th>Action</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].name">{{ data[indextr].name | capitalize}}</vs-td>
            <vs-td :data="data[indextr]._id">
              <div class="flex gap-2">
                <vx-tooltip :text="keyAccountId == data[indextr]._id ? 'Current Key Account Value' : 'Mark as Key Account Value'">
                  <vs-button
                    type="flat"
                    size="small"
                    :color="keyAccountId == data[indextr]._id ? 'warning' : 'primary'"
                    @click="markAsValKeyAccount(data[indextr])"
                    icon-pack="feather"
                    icon="icon-star"
                  ></vs-button>
                </vx-tooltip>
                <vs-button
                  type="border"
                  size="small"
                  @click="openEditPopUp(data[indextr])"
                  icon-pack="feather"
                  icon="icon-edit"
                ></vs-button>
                <vs-button
                  type="border"
                  size="small"
                  @click="openDeletePopUp(data[indextr])"
                  icon-pack="feather"
                  icon="icon-trash"
                ></vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
    <vs-popup title="Account Level" :active.sync="popupActive">
      <div class="p-3 overflow-hidden">
        <vs-input
          v-model="accountLevelData.name"
          data-vv-as="name"
          :danger="errors.first('name')"
          :danger-text="errors.first('name')"
          :success="!errors.first('name') && accountLevelData.name != ''"
          val-icon-success="done"
          val-icon-danger="error"
          v-validate="'required'"
          name="name"
          label="Account Level Name"
          placeholder="Account Level Name"
          class="w-full"
        />
        <div class="flex flex-wrap md:flex-no-wrap items-center justify-between space-y-2 md:space-y-0 md:space-x-4 mt-5">
          <div class="w-full md:flex-1">
            <vs-button type="border" class="w-full" @click="popupActive=false">
              Cancel
            </vs-button>
          </div>
          <div class="w-full md:flex-1">
            <vs-button class="w-full" @click="submitData">Submit</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
    <vs-popup title="Delete Account Level" :active.sync="deletePopupActive">
      <div class="p-3 overflow-hidden">
        <p>Are you sure you want to delete this account level?</p>
        <p>
          <b>{{ this.accountLevelData.name }}</b>
        </p>
        <div class="flex flex-wrap md:flex-no-wrap items-center justify-between space-y-2 md:space-y-0 md:space-x-4 mt-5">
          <div class="w-full md:flex-1">
            <vs-button type="border" class="w-full" @click="deletePopupActive=false">
              Cancel
            </vs-button>
          </div>
          <div class="w-full md:flex-1">
            <vs-button color="danger" class="w-full" @click="confirmDelete">Confirm</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
    <LogsSidebar :activity_logs="activityLogs" ref="logsSidebar" />
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import LogsSidebar from "@/views/components/account-creation-logs/logs.vue";

Validator.localize("en", {
  custom: {
    name: {
      required: "Account Level Name is empty",
    },
  },
});

export default {
  name: "AccountLevels",
  components: {
    LogsSidebar
  },
  data() {
    return {
      noDataText: "Loading...",
      isEdit: false,
      accountLevels: [],
      accountLevelData: {
        _id: null,
        name: '',
      },
      popupActive: false,
      deletePopupActive: false,
      activityLogs: '',
      updaterData: null,
      type: 'account-level-fields',
      keyAccountId: null,
    };
  },
  methods: {
    ...mapActions("accountLevels", ["fetchAccountLevels", "createAccountLevel", "updateAccountLevel", "deleteAccountLevel"]),
    ...mapActions("appUser", ["getAccountCreationLogs"]),
    ...mapActions("admin", ["getSettingsByKey", "updateSettingsByKey"]),
    getAccountLevelsList() {
      this.$vs.loading();

      this.fetchAccountLevels().then(async res => {
        if (res.data.data.length > 0) {
          this.accountLevels = res.data.data;
          await this.getKeyAccountId();
        } else {
          this.noDataText = "No Data Available";
        }
      }).catch(() => {
        this.noDataText = "Unable to fetch data";
        this.accountLevels = [];
      }).finally(() => {
        this.$vs.loading.close();
      });
    },
    async getKeyAccountId() {
      const { data } = await this.getSettingsByKey("accountLevelFieldValues");
      this.keyAccountId = data.data.value.keyAccountLevelId;
    },
    async markAsValKeyAccount(data) {
      this.$vs.loading();
      let tempKeyAccountLevelId = '';

      if (data._id != this.keyAccountId) {
        tempKeyAccountLevelId = data._id;
      }

      await this.updateSettingsByKey({
        key: "accountLevelFieldValues",
        value: {
          keyAccountLevelId: tempKeyAccountLevelId
        }
      }).then(res => {
        this.$vs.notify({
          title: "Success",
          text: "Key account level ID set successfully",
          color: "success"
        });
      }).catch(() => {
        this.$vs.notify({
          title: "Error",
          text: "Unable to update key account level ID",
          color: "danger"
        });
      }).finally(() => {
        this.getAccountLevelsList();
        this.$vs.loading.close();
      });
    },
    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.isEdit) {
            const originalData = this.$refs.table.data.find(item => item._id === this.accountLevelData._id);

            if (originalData.name === this.accountLevelData.name) {
              this.$vs.notify({
                title: "No Changes",
                text: "No changes were made to the account level name",
                color: "warning"
              });
              return;
            }
          }
          this.saveData();
        }
      });
    },
    async saveData() {
      this.$vs.loading();

      const localStorageData = this.getLocalStorageData();

      this.updaterData = {
        userId: localStorageData._id,
        type: this.type,
        updater: localStorageData.firstName + ' ' + localStorageData.lastName,
        method: this.isEdit ? 'UPDATE' : 'CREATE',
        updatedFields: !this.isEdit ? null : {
          accountLevelName: {
            oldValue: this.$refs.table.data.find(item => item._id === this.accountLevelData._id).name,
            newValue: this.accountLevelData.name,
          }
        },
        name: this.accountLevelData.name,
        nameId: this.accountLevelData._id ? this.accountLevelData._id : null,
      }

      if (this.isEdit) {
        await this.updateAccountLevel({...this.accountLevelData, log: {...this.updaterData}}).then(res => {
          this.$vs.notify({
            title: "Success",
            text: "Account Level updated successfully.",
            color: "success",
          });
        }).catch(() => {
          this.$vs.notify({
            title: "Failed",
            text: 'Update failed, please try again.',
            color: "danger",
          });
        }).finally(() => {
          this.closePopup();
        });
      } else {
        await this.createAccountLevel({...this.accountLevelData, log: {...this.updaterData}}).then(res => {
          this.$vs.notify({
            title: "Success",
            text: "Account Level created successfully.",
            color: "success",
          });
        }).catch(() => {
          this.$vs.notify({
            title: "Failed",
            text: 'Adding failed, please try again.',
            color: "danger",
          });
        }).finally(() => {
          this.closePopup();
        });
      }
    },
    async confirmDelete() {
      this.$vs.loading();

      const localStorageData = this.getLocalStorageData();

      this.updaterData = {
        userId: localStorageData._id,
        type: this.type,
        updater: localStorageData.firstName + ' ' + localStorageData.lastName,
        method: 'DELETE',
        updatedFields: null,
        name: this.accountLevelData.name,
        nameId: this.accountLevelData._id ? this.accountLevelData._id : null,
      }

      await this.deleteAccountLevel({
        ...this.accountLevelData,
        log: {...this.updaterData},
        deleteKeyAccountLevelId: this.accountLevelData._id == this.keyAccountId
      }).then(res => {
        this.$vs.notify({
          title: "Success",
          text: `Account Level ${this.accountLevelData.name} deleted successfully.`,
          color: "success",
        });
      }).catch(() => {
        this.$vs.notify({
          title: "Failed",
          text: 'Deleting failed, please try again.',
          color: "danger",
        });
      }).finally(() => {
        this.closePopup();
      });
    },
    async closePopup() {
      await this.getAccountLevelsList();
      this.deletePopupActive = false;
      this.popupActive = false;
      this.resetPopup();
      this.$validator.reset();
      this.$vs.loading.close();
    },
    resetPopup() {
      this.accountLevelData._id = null;
      this.accountLevelData.name = "";
    },
    setPopupValues(data) {
      this.accountLevelData._id = data._id;
      this.accountLevelData.name = data.name;
    },
    openCreatePopUp() {
      this.resetPopup();
      this.$validator.reset();
      this.popupActive = true;
      this.isEdit = false;
    },
    openEditPopUp(data) {
      this.$validator.reset();
      this.setPopupValues(data);
      this.popupActive = true;
      this.isEdit = true;
    },
    openDeletePopUp(data) {
      this.setPopupValues(data);
      this.deletePopupActive = true;
    },
    async openAccountLevelLogs() {
      this.$vs.loading();
      this.$refs.logsSidebar.openSidebar();

      await this.getAccountCreationLogs({
        type: 'account-level-fields'
      }).then(res => {
        this.activityLogs = res
      }).catch(err => {
        console.warn(err)
      }).finally(() => {
        this.$vs.loading.close();
      });
    },
    getLocalStorageData() {
      return JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_PROCESS_TOKEN}User`)
      );
    },
  },
  async created() {
    this.getAccountLevelsList();
  },
};
</script>
