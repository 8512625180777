<template>
  <transition name="fade">
    <div class="flex flex-col items-between justify-between list-view-item rounded-lg h-full p-1" v-if="showList">
      <div class="w-full cursor-pointer flex justify-center product-image mt-4">
        <img @click="navigate_to_detail_view" class="block product-image-list object-contain w-full"
          v-if="item.images.length > 0" :src="item.images[ 0 ].image" />
      </div>

      <div class="flex flex-1 flex-col justify-between items-center">
        <div class="flex flex-col justify-center px-4 lg:p-3 w-full">

          <div v-if="item.subDescription" v-html="item.subDescription"
            class="text-white text-center rounded uppercase text-sm custom-primary-bg self-center px-2 py-1 mb-2 w-full script-amount" />
          <div v-else class="filler"></div>

          <div
            v-if="item.brand && item.brand.length && isBrandVisible(item.brand[0].brandName)"
            @click="navigate_to_brand_view(item.brand[0].brandName)"
            class="font-semibold hover:underline cursrrp-price text-primary mb-4 text-xs cursor-pointer"
          >
            {{ item.brand[0].brandName }}
          </div>
          <div v-if="item.brand" @click="navigate_to_brand_view(item.brand.name)"
            class="font-semibold hover:underline cursrrp-price text-primary mb-4 text-xs cursor-pointer">
            {{ item.brand.name }}
          </div>

          <vx-tooltip :text="item.name" position="bottom">
            <div @click="navigate_to_detail_view"
              class="text-primary2 font-medium text-sm md:text-base cursor-pointer hover:underline title-min-height ellipsis">
              {{ item.name }}
            </div>
          </vx-tooltip>

          <div class="text-xl font-bold text-primary mt-6 mb-0"
            :class="item.compareAtPrice || item.listPrice ? 'mb-0' : 'mb-5'">
            <!-- ${{ item.price | formatDecimals }} -->
            ${{ hasDiscount(item._id) ? hasDiscount(item._id) : item.price | formatDecimals }}
          </div>

          <!-- price per measurement -->
          <div class="price-per-measurement text-primary2 mt-2 mb-2 text-xs" v-if="item.isPricePerMeasurementHidden">
            ${{ measurementPrice }}
          </div>
          <!-- end of price per measurement -->
          <div v-if="!item.isRRPHidden">
            <div class="rrp-price text-primary2 text-xs mb-4" v-if="item.compareAtPrice">
              RRP <span :class="item.isCompareAtPriceToggled && 'line-through'">${{ item.compareAtPrice | formatDecimals
                }}</span>
            </div>
          </div>

          <div v-show="!item.isListPriceHidden" class="text-primary2 mb-1 text-xs" v-if="item.listPrice">
            List Price <span :class="item.isListPriceToggled && 'line-through'">${{ item.listPrice | formatDecimals
              }}</span>
          </div>
        </div>

        <!-- action -->
        <div class="flex flex-col justify-center items-end w-full p-3 gap-3">
          <div class="w-full relative">

            <small class="block font-medium text-red-600 text-center mb-1 min-error-text px-4"> {{ minMaxErrorText }}
            </small>

            <div class="flex flex-1 justify-center w-full"
              v-if="(item.canBuy || superAdminCanAddToCart) && item.isAvailable">
              <div v-if="inCart" class="w-full flex flex-row rounded-lg py-1  shop-button items-center">

                <button
                  class="w-2/12 flex justify-center items-center text-2xl cursor-pointer sm:font-normal md: font-bold"
                  style="min-width: 33px; height: 33px;" @click="decrease(
                    getCartInfo(item._id).quantity,
                    item,
                    item.inventory.requiredQuantity
                  )"> &minus;
                </button>

                <input name="qty" type="number" min="item.inventory.requiredQuantity" class="w-full text-lg"
                  ref="qtyInput" :value="cartProduct(item._id)" @input="updateCartProduct($event, item)"
                  @blur="minMaxErrorText = ''" />

                <button
                  class="w-2/12 flex justify-center items-center text-2xl cursor-pointer sm:font-normal md: font-bold"
                  style="min-width: 33px; height: 33px;" @click="increase(item)"> &plus;
                </button>
              </div>
              <span v-if="item.free_shipping" class="text-grey flex items-start justify-center mt-1">
                <feather-icon icon="ShoppingCartIcon" svgClasses="w-4 h-4" />
                <span class="text-sm ml-2">Free Shipping</span>
              </span>

            </div>
          </div>

          <slot name="action-buttons" :item2="item" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions } from "vuex";
import StoreHelper from "@/mixins/Store.vue";

// Utils
import {
  trackEcommerceEvent,
  formatPriceValue,
  SELECT_ITEM_LIST_EVENT,
  ADD_TO_CART_EVENT
} from '../../../utils/gtag'

export default {
  mixins: [StoreHelper],
  inject: ['getRoleRouteName'],
  data() {
    return {
      quantity: 1,
      showList: false,
      cartItems: [],
      minMaxErrorText: '',
      userTypeAdmin: undefined,
      discounts: [],
      clinic: null
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {
        return {
          inventory: {}
        }
      }
    },
    index: {
      type: Number,
      default: 0,
    },
    inCart: {
      type: Boolean,
      default: false
    },
    superAdminCanAddToCart: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getCartInfo() {
      return (itemId) => this.$store.getters["eCommerce/getCartItemTest"](itemId);
    },
    isInCartTest() {
      return (itemId) => this.$store.getters["eCommerce/isInCartTest"](itemId);
    },
    isInCart() {
      return (itemId) => this.$store.getters["eCommerce/isInCart"](itemId);
    },
    isInWishList() {
      return (itemId) => this.$store.getters["eCommerce/isInWishList"](itemId);
    },
    cartProduct() {
      return (itemId) => this.$store.getters["eCommerce/totalItemQuantityIncart"](itemId);
    },
    measurementPrice() {
      const price = (this.item.price / this.item.pricePerMeasurement.price).toFixed(2)
      return `${price} ${this.item.pricePerMeasurement.unit}`
    },
  },
  watch: {
    "item.inventory": function () {
      if (
        this.item.inventory.requiredQuantity !== undefined &&
        this.item.inventory.requiredQuantity !== 0
      ) {
        this.quantity = this.item.inventory.requiredQuantity;
        this.item.selectedQuantity = this.item.inventory.requiredQuantity;
      } else {
        this.quantity = 1;
        this.item.inventory.requiredQuantity = 1;
        this.item.selectedQuantity = 1;
      }
    },
    "$store.state.eCommerce.cartItems": {
      handler(val) {
        this.cartItems = JSON.parse(JSON.stringify(val.slice().reverse()))
      },
      immediate: true
    },
    "$store.state.clinicId": function (val) {
      this.fetchClinic()
    },
  },
  methods: {
    ...mapActions("discount", ["fetchDiscountList"]),
    ...mapActions("clinic", ["fetchClinicsDetail"]),

    hasDiscount(productId) {
      const clinicData = this.clinic;

      if (clinicData && clinicData.data && clinicData.data.data && clinicData.data.data.clinicDiscounts.length > 0) {
        for (let discount of this.discounts) {
          if (clinicData.data.data.clinicDiscounts.includes(discount._id)) {
            for (let productDiscount of discount.productDiscounts) {
              if (productDiscount.productStoreId === productId && productDiscount.isActive) {
                return Number(productDiscount.adjustedPrice);
              }
            }
          }
        }
      }

      return null;

    },
    removeFromCart(item, carItem) {
      let prod = {
        data: item,
        qty: 0
      };
      this.$store.commit("eCommerce/UPDATE_ITEM_QUANTITY_TEST", prod);
      this.$store.dispatch("eCommerce/toggleItemInCart", carItem);
      this.minErrorText = false
    },
    updateCartProduct(e, item) {
      const carItem = this.getCartInfo(item._id)
      const value = parseInt(e.target.value)
      const minQty = item.inventory.requiredQuantity;
      const maxQty = item.inventory.maxQuantity;
      let qty = value

      if (this.timeout) clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {

        if (value < minQty || value < 1 || isNaN(value) || value == '') {
          this.minMaxErrorText = `Minimum quantity is ${minQty}.`
          e.target.value = minQty;
          qty = minQty
        } else if (maxQty > 0 && value > maxQty) {
          this.minMaxErrorText = `Maximum quantity is ${maxQty}.`
          e.target.value = maxQty;
          qty = maxQty
        } else {
          this.minMaxErrorText = ''
          e.target.value = qty;
        }

        let prod = {
          data: item,
          qty: qty
        };

        this.$store.commit("eCommerce/UPDATE_ITEM_QUANTITY_TEST", prod);

        this.$store.dispatch("eCommerce/updateItemQuantity", {
          item: carItem

        }).then((response) => {

          if (response.message !== 'success') {
            this.$vs.notify({
              color: 'danger',
              title: `Product Not Updated`,
              text: `We are having issues updating your cart. Please try again.`
            });
          }
        });

      }, 1000);

    },
    removeItemFromCart(item) {
      this.$store.dispatch("eCommerce/toggleItemInCart", item);
    },
    toggleItemInWishList(item) {
      this.$store.dispatch("eCommerce/toggleItemInWishList", item);
    },
    increase(data) {
      const cartQuantity = this.cartProduct(data._id);

      const totalCartAndSelectedQty = cartQuantity + 1;

      if (
        data.inventory.maxQuantity !== null &&
        data.inventory.maxQuantity !== 0
      ) {
        if (totalCartAndSelectedQty > data.inventory.maxQuantity) {
          this.$vs.notify({
            title: "Failed",
            text: "You'll exceed maximum order amount.",
            color: "danger",
          });
          return false;
        }
      }
      this.selectedChangeQuantity(1, data, 'increment');
    },
    decrease(quantity, data, requiredQuantity) {
      let cartItem = this.getCartInfo(data._id);
      let cartQty = cartItem.quantity

      if (quantity - 1 >= requiredQuantity) {
        this.quantity = quantity - 1;
        this.selectedChangeQuantity(quantity, data, 'decrement');

      } else {
        if ((cartQty - 1) < 1) {
          this.removeItemFromCart(cartItem)
        } else {
          this.errors.add({
            field: "MinimumQuantityLimit",
            msg:
              "Minimum order quantity of " +
              data.name +
              " is " +
              data.inventory.requiredQuantity,
            id: data._id,
          });
          this.$vs.notify({
            title: "Error",
            text: `Quantity cannot be lower than ${data.inventory.requiredQuantity}`,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
          return false;
        }
      }
    },
    selectedChangeQuantity(quantity, data, type) {
      this.$emit("quantityChange", {
        index: this.index,
        quantity: this.quantity,
      });

      this.errors.removeById(data._id);
      this.item.selectedQuantity = quantity;
      this.additemInCart(data, type)

    },
    additemInCart(data, type) {
      let item = { data: data, type: type }
      let messageType = type === 'decrement' ? 'removed from your cart' : 'added to your cart'

      this.$vs.loading();
      this.$store.dispatch("eCommerce/additemInCart", item).then((response) => {
        trackEcommerceEvent(ADD_TO_CART_EVENT, {
          item_list_id: "shop_category",
          item_list_name: "Shop Category",
          items: [
            {
              item_id: item._id,
              item_name: item.name,
              quantity: item.quantity,
              price: formatPriceValue(item.price),
            }
          ]
        });

        if (response.status === 200 && response.data.message === 'success') {
          this.$vs.notify({
            color: 'success',
            title: `Product ${messageType}`,
            text: `Product successfully ${messageType}.`
          })
        } else {
          this.$vs.notify({
            color: 'danger',
            title: `Product Not Updated`,
            text: `We are having issues updating your cart. Please try again.`
          })
        }
        this.$vs.loading.close();
      });

    },
    cartButtonClicked(item) {
    },
    navigate_to_detail_view() {
      let routername;

      if (this.userTypeAdmin && this.userTypeAdmin === "superAdmin") {
        routername = "SuperAdminProductDetail"

      } else {
        routername = this.getRoleRouteName("ProductDetail")
      }

      trackEcommerceEvent(SELECT_ITEM_LIST_EVENT, {
        item_list_id: "shop_category",
        item_list_name: "Shop Category",
        items: [
          {
            item_id: this.item._id,
            item_name: this.item.name,
            quantity: 1,
            price: formatPriceValue(this.item.price),
          }
        ]
      });

      this.$router
        .push({
          name: routername,
          params: {
            id: this.item._id,
          },
        })
        .catch(() => { });
    },
    navigate_to_brand_view(brand) {
      let routername;
      const formattedBrandName = brand ? brand.toLowerCase().replace(/\s+/g, '-') : null;

      if (this.userTypeAdmin && this.userTypeAdmin === "superAdmin") {
        routername = "SuperAdminOrderCenterBrandPage"

      } else {
        routername = this.getRoleRouteName("OrderCenterBrandPage")
      }

      this.$router
        .push({
          name: routername,
          params: {
            slug: formattedBrandName,
          },
          query: {
            page: 1
          }
        });
    },
    async fetchClinic() {
      this.clinic = await this.fetchClinicsDetail(sessionStorage.getItem("doctorClinicSelectedClinic"));
    }
  },
  async created() {
    await this.fetchDiscountList().then((response) => {
      this.discounts = response.data.data;
    });

    if (this.item.inventory) {
      if (
        this.item.inventory.requiredQuantity !== undefined &&
        this.item.inventory.requiredQuantity !== 0
      ) {
        this.quantity = this.item.inventory.requiredQuantity;
      } else {
        this.quantity = 1;
        this.item.inventory.requiredQuantity = 1;
      }
    }
    this.userTypeAdmin = this.$store.state.AppActiveUser.userType
    this.item.selectedQuantity = this.quantity;
    this.fetchClinic()
  },
  mounted() {
    setTimeout(() => { this.showList = true; }, 500);
  }
};
</script>

<style lang="scss" scoped>
.custom-primary-bg {
  background-color: #84CDB8;
}

.custom-primary-text {
  color: #84CDB8;
}

.list-view-item {

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s ease-in-out;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 1;
  }

  .increment-buttons {
    background: #3cc9b230;
  }

  border-color: transparent;

  @media (min-width: 922px) {}

}
</style>


<style lang='scss'>
.ellipsis {
  height: 50px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.filler {
  margin: 1.2rem 0;
}

.product {
  &-image {
    min-height: 200px;
  }

  &-image-list {
    border-radius: 1.5rem;
    border: none;
    max-width: 200px;
    max-height: 200px;
  }
}

.script-amount {
  p {
    strong {
      font-weight: 400;
      text-transform: capitalize;
    }
  }
}

.shop-button {
  background-color: #0E4333;

  button {
    color: #fff;
    background: transparent;
    -webkit-appearance: none;
    border: none;
    width: 100%;
    transition: transform 500ms ease-out;

    i {
      font-size: 1.2rem;
    }

    &:hover {
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    &:active {
      transform: scale(1.4);
      transform-origin: center;
    }
  }

  input[type=number] {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none !important;
    background-color: white;
    text-align: center;
    border-radius: .2rem;
    padding: 0.63rem 0;

    &:focus {
      box-shadow: none;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.min-error-text {
  top: -16px;
  font-size: 10px;
}


@media (min-width: 992px) {
  .ellipsis {
    height: 80px;
    -webkit-line-clamp: 4;
  }
}

@media screen and (max-width: 600px) {
  .increment-buttons {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important;
  }
}
</style>
