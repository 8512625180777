<template>
  <div>
    <div id="ecommerce-checkout-demo">

      <div class="w-full flex flex-col lg:flex-row">
        <div class="w-full lg:w-3/5">
          <Tabs :onClick="onClick" :active="active" />
          <div class="py-4">
            <form-wizard ref="checkoutWizard" color="rgba(var(--vs-primary), 1)" :title="null" :subtitle="null"
              :hide-buttons="true">
              <!-- STORE LIMITATION -->
              <div>
                <vs-card :key="limitation._id" v-for="limitation in limitationsList">
                  <p v-if="limitation.isAboveMax" class="text-base text-danger">
                    {{ limitation.display_message_max }}
                  </p>
                  <p v-if="limitation.isBelowMin" class="text-base text-danger">
                    {{ limitation.display_message_min }}
                  </p>
                  <p v-if="limitation.isBellowAmount" class="text-base text-danger">
                    {{ limitation.display_purchase_message_min }}
                  </p>
                </vs-card>
              </div>
              <!-- STORE LIMITATION -->
              <!-- OPENING ORDER Banner -->
              <div>
                <vs-card v-if="!meetOpeningOrder">
                  <p class="text-base text-danger">
                    Contact Fresh - opening order requirement does not meet.
                  </p>
                </vs-card>
              </div>
              <!-- OPENING ORDER Banner -->
              <!-- Inactive Items (Expandable) -->
              <div v-if="active === 1 && inactiveCartItems.length > 0"
                class="border border-gray-300 p-4 rounded-md bg-gray-100">
                <div class="flex items-center">
                  <div>
                    <button @click="removeAllInactive"
                      class=" bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 border-none">
                      Remove all inactive product/s ({{ inactiveCartItems.length }})
                    </button>
                  </div>
                  <div v-if="inactiveCartItems.length > 1" class="ml-2 cursor-pointer" @click="toggleInactiveItems">
                    <h6 class="font-semibold mb-1 hover:text-primary">
                      <span v-if="!showInactive" class="text-primary">(Show More)</span>
                      <span v-if="showInactive" class="text-primary">(Show Less)</span>
                    </h6>
                  </div>
                </div>
                <div class="hidden md:flex px-3 pt-2">
                  <div class="w-1/3">
                    <div>Item</div>
                  </div>
                  <div class=" w-8/12 flex justify-end pb-2">
                    <div class="w-1/4 my-auto text-center">Price</div>
                    <div class="w-1/4 my-auto text-center">Total</div>
                    <div class="w-1/4 my-auto text-center">{{ discountsArray.length > 0 ? "Savings" : '' }}</div>
                    <div class="w-1/4 my-auto text-center">Quantity</div>
                  </div>

                </div>
                <div class="items-list-view">
                  <div v-for="item in limitedInactiveItems" :key="item._id" class="mb-4">
                    <checkout-list-view :item="item" :isHidden="true" :discounts="discountsArray"
                      :hasClinicDiscount="hasClinicDiscount" class="mb-4" :isDoctorStore="isDoctorStore"
                      @fetchCartItems="getCartItems">
                      <!-- SLOT: ITEM META -->
                      <template slot="remove">
                        <div class="w-1/2 lg:w-32 flex items-center justify-center">
                          <x-icon size="1.5x" class="cursor-pointer" @click="removeItemFromCart(item)"></x-icon>
                        </div>
                      </template>
                      <template slot="item-meta">
                        <h6 class="item-name font-semibold mb-1 cursor-pointer hover:text-primary" @click="
                          $router
                            .push({
                              name: 'ecommerce-item-detail-view',
                              params: { item_id: item._id },
                            })
                            .catch(() => { })
                          ">
                          {{ item.name }}
                        </h6>
                        <p class="text-sm mb-2">
                          By
                          <span class="font-semibold cursor-pointer">{{
                            item.brand
                          }}</span>
                        </p>
                        <p class="text-success text-sm">In Stock</p>

                        <br />
                        <span class="text-danger" style="font-size: 0.75em">{{
                          errors.firstById(item._id)
                        }}</span>
                      </template>

                      <!-- SLOT: ACTION BUTTONS -->
                      <template slot="action-buttons">
                        <div class="w-full lg:w-auto flex flex-col justify-end items-end lg:justify-none">
                          <div v-if="!item.bundleItems.length" class="w-full lg:w-32 flex flex-row justify-center">
                            <div class=" flex justify-center items-center text-4xl text-primary cursor-pointer"
                              @click="decreaseQuantity(item)">
                              <!-- <div>-</div> -->
                              <vs-icon icon="remove_circle_outline" size="25px"></vs-icon>
                            </div>
                            <div class="flex justify-center items-center text-lg mx-2">
                              <div>{{ item.quantity }}</div>
                            </div>
                            <div class=" flex justify-center items-center text-4xl text-primary cursor-pointer"
                              @click="addQuantity(item)">
                              <!-- <div>+</div> -->
                              <vs-icon icon="add_circle_outline" size="25px"></vs-icon>
                            </div>
                          </div>
                          <!-- PRIMARY BUTTON: REMOVE -->
                          <div
                            class=" w-full lg:w-32 p-1 mt-2 cursor-pointer text-danger border text-center pointer-events-auto opacity-100"
                            @click="removeItemFromCart(item)">
                            Remove
                          </div>
                        </div>
                      </template>
                    </checkout-list-view>
                  </div>
                </div>
              </div>
              <!-- Active Items -->
              <div v-if="active === 1 && cartItems.length">
                <div class="hidden md:flex px-3 pt-4">
                  <div class="w-1/3">
                    <div>Item</div>
                  </div>
                  <div class=" w-8/12 flex justify-end pb-2">
                    <div class="w-1/4 my-auto text-center">Price</div>
                    <div class="w-1/4 my-auto text-center">Total</div>
                    <div class="w-1/4 my-auto text-center">{{ discountsArray.length > 0 ? "Savings" : '' }}</div>
                    <div class="w-1/4 my-auto text-center">Quantity</div>
                  </div>
                </div>

                <div class="items-list-view" v-for="item in cartItems" :key="item._id">
                  <checkout-list-view :item="item" :discounts="discountsArray" :hasClinicDiscount="hasClinicDiscount"
                    class="mb-4" :isDoctorStore="isDoctorStore" @fetchCartItems="getCartItems">
                    <!-- SLOT: ITEM META -->

                    <template slot="item-meta">
                      <h6 class="item-name font-semibold mb-1 cursor-pointer hover:text-primary" @click="
                        $router
                          .push({
                            name: 'ecommerce-item-detail-view',
                            params: { item_id: item._id },
                          })
                          .catch(() => { })
                        ">
                        {{ item.name }}
                      </h6>
                      <p class="text-sm mb-2">
                        By
                        <span class="font-semibold cursor-pointer">{{
                          item.brand
                          }}</span>
                      </p>
                      <p class="text-success text-sm">In Stock</p>

                      <br />
                      <span class="text-danger" style="font-size: 0.75em">{{
                        errors.firstById(item._id)
                        }}</span>
                    </template>

                    <!-- SLOT: ACTION BUTTONS -->
                    <template slot="action-buttons">
                      <div class="w-full lg:w-auto flex flex-col justify-end items-end lg:justify-none">
                        <div v-if="!item.bundleItems.length" class="w-full lg:w-32 flex flex-row justify-center">
                          <div class=" flex justify-center items-center text-4xl text-primary cursor-pointer"
                            @click="decreaseQuantity(item)">
                            <!-- <div>-</div> -->
                            <vs-icon icon="remove_circle_outline" size="25px"></vs-icon>
                          </div>
                          <div class="flex justify-center items-center text-lg mx-2">
                            <div>{{ item.quantity }}</div>
                          </div>
                          <div class=" flex justify-center items-center text-4xl text-primary cursor-pointer"
                            @click="addQuantity(item)">
                            <!-- <div>+</div> -->
                            <vs-icon icon="add_circle_outline" size="25px"></vs-icon>
                          </div>
                        </div>

                        <!-- PRIMARY BUTTON: REMOVE -->
                        <div
                          class=" w-full lg:w-32 p-1 mt-2 cursor-pointer text-danger border text-center pointer-events-auto opacity-100"
                          @click="removeItemFromCart(item)">
                          Remove
                        </div>
                      </div>
                    </template>
                  </checkout-list-view>
                </div>
              </div>
              <div v-if="active === 2">
                <vx-card title="Shipping Details" subtitle="" class="mb-base">
                  <form data-vv-scope="add-new-address" class="add-new-address-form">
                    <p class="pb-2 font-semibold">Delivery Address</p>
                    <p>{{ shippingDetails.address.displayAddress }}</p>
                    <p class="py-5 font-semibold">Hours of operation</p>
                    <div class="w-full sm:w-1/2">
                      <div class="vx-row">
                        <div class="vx-col sm:w-1/5 w-full">
                          <p class="pt-2">Monday</p>
                        </div>
                        <div class="vx-col sm:w-4/5 w-full">
                          <vs-input data-vv-as="field" name="monday" class="w-full" placeholder="9-5 or closed"
                            :danger="!operations.monday" val-icon-danger="error" v-model="operations.monday" />
                        </div>
                      </div>
                      <div class="vx-row mt-2">
                        <div class="vx-col sm:w-1/5 w-full">
                          <p class="pt-2">Tuesday</p>
                        </div>
                        <div class="vx-col sm:w-4/5 w-full">
                          <vs-input data-vv-as="field" name="tuesday" class="w-full" placeholder="9-5 or closed"
                            :danger="!operations.tuesday" val-icon-danger="error" v-model="operations.tuesday" />
                        </div>
                      </div>
                      <div class="vx-row mt-2">
                        <div class="vx-col sm:w-1/5 w-full">
                          <p class="pt-2">Wednesday</p>
                        </div>
                        <div class="vx-col sm:w-4/5 w-full">
                          <vs-input data-vv-as="field" name="wednesday" class="w-full" placeholder="9-5 or closed"
                            :danger="!operations.wednesday" val-icon-danger="error" v-model="operations.wednesday" />
                        </div>
                      </div>
                      <div class="vx-row mt-2">
                        <div class="vx-col sm:w-1/5 w-full">
                          <p class="pt-2">Thursday</p>
                        </div>
                        <div class="vx-col sm:w-4/5 w-full">
                          <vs-input data-vv-as="field" name="thursday" class="w-full" placeholder="9-5 or closed"
                            :danger="!operations.thursday" val-icon-danger="error" v-model="operations.thursday" />
                        </div>
                      </div>
                      <div class="vx-row mt-2">
                        <div class="vx-col sm:w-1/5 w-full">
                          <p class="pt-2">Friday</p>
                        </div>
                        <div class="vx-col sm:w-4/5 w-full">
                          <vs-input data-vv-as="field" name="friday" class="w-full" placeholder="9-5 or closed"
                            :danger="!operations.friday" val-icon-danger="error" v-model="operations.friday" />
                        </div>
                      </div>
                      <div class="vx-row mt-2">
                        <div class="vx-col sm:w-1/5 w-full">
                          <p class="pt-2">Saturday</p>
                        </div>
                        <div class="vx-col sm:w-4/5 w-full">
                          <vs-input data-vv-as="field" name="saturday" class="w-full" placeholder="9-5 or closed"
                            :danger="!operations.saturday" val-icon-danger="error" v-model="operations.saturday" />
                        </div>
                      </div>
                      <div class="vx-row mt-2">
                        <div class="vx-col sm:w-1/5 w-full">
                          <p class="pt-2">Sunday</p>
                        </div>
                        <div class="vx-col sm:w-4/5 w-full">
                          <vs-input data-vv-as="field" name="sunday" class="w-full" placeholder="9-5 or closed"
                            :danger="!operations.sunday" val-icon-danger="error" v-model="operations.sunday" />
                        </div>
                      </div>
                    </div>
                    <div class="vx-row hidden">
                      <div class="vx-col sm:w-1/2 w-full">
                        <vs-input data-vv-as="field" name="clinicName" label="Clinic Name:"
                          v-model="shippingDetails.clinicName" class="w-full mt-5" disabled />
                        <!--                    <span v-show="errors.has('add-new-address.fullName')" class="text-danger">{{ errors.first('add-new-address.fullName') }}</span>-->
                      </div>

                      <div class="vx-col sm:w-1/2 w-full">
                        <vs-input name="email" label="Email:" v-model="shippingDetails.email" class="w-full mt-5"
                          disabled />
                        <!--                    <span v-show="errors.has('add-new-address.mobileNum')" class="text-danger">{{ errors.first('add-new-address.mobileNum') }}</span>-->
                      </div>
                    </div>

                    <div class="vx-row hidden">
                      <div class="vx-col sm:w-1/2 w-full">
                        <vs-input name="displayAddress" label="Address:"
                          v-model="shippingDetails.address.displayAddress" class="w-full mt-5" disabled />
                        <!--                    <span v-show="errors.has('add-new-address.houseNum')" class="text-danger">{{ errors.first('add-new-address.houseNum') }}</span>-->
                      </div>

                      <div class="vx-col sm:w-1/2 w-full">
                        <vs-input name="postCode" label="Post Code:" v-model="shippingDetails.address.postCode"
                          class="w-full mt-5" disabled />
                      </div>
                    </div>

                    <div class="vx-row hidden">
                      <div class="vx-col sm:w-1/2 w-full">
                        <vs-input v-validate="'required'" name="state" label="State:"
                          v-model="shippingDetails.address.state" class="w-full mt-5" disabled />
                      </div>

                      <div class="vx-col sm:w-1/2 w-full">
                        <vs-input name="suburb" label="Suburb:" v-model="shippingDetails.address.suburb"
                          class="w-full mt-5" disabled />
                      </div>
                    </div>

                    <p class="mt-10">
                      If you wish to change your delivery address please contact
                      fresh clinics.
                    </p>
                    <p class="mt-4">info@freshclinics.com.au</p>
                  </form>
                </vx-card>
              </div>
              <div v-if="active === 3">
                <div class="relative">
                  <vs-popup class="holamundo" title="Add payment method" :active.sync="popupActive" @close="closePopup">
                    <vs-tabs :value="paymentType === 'CreditCard' ? 0 : 1">
                      <vs-tab label="Credit Card" @click="paymentType = 'CreditCard'" v-if="showCreditCard">
                        <stripe-elements ref="stripeElement" v-bind:title="saveCreditCard" :pk="stripePublishableKey"
                          :amount="totalPrice" locale="en" @token="tokenCreated" @loading="loading = $event"
                          @error="errorMessage">
                        </stripe-elements>
                        <vs-button @click="submit">Add</vs-button>
                      </vs-tab>
                      <vs-tab label="Bank Account" @click="paymentType = 'ClinicBankAccount'"
                        v-if="region === 'AU' && showBankAccount">
                        <BankSetup :totalPrice="totalPrice" :financialDetails="financialDetails"
                          :isNewClinicBankAccount="isNewClinicBankAccount" :shippingDetails="shippingDetails"
                          :paymentType="'ClinicBank'" :repayment="true" :buttonTitle="'Save'" :clinicId="clinicId"
                          :payment-email="orgOwnerEmail" @onClinicBankPaymentSuccess="clinicBankPaymentSuccess">
                        </BankSetup>
                      </vs-tab>
                    </vs-tabs>
                  </vs-popup>
                  <div class="bg-white flex flex-col roundex-lg shadow-lg rounded-lg">
                    <h4 class="px-6 pt-4 pb-2">Payment options</h4>
                    <h5 class="px-6 pb-2 text-base font-normal">Selected Payment Methods</h5>
                    <div>

                      <ul>
                        <li v-for="(card, index) in paymentMethods" :key="index">
                          <div v-if="card.isPaymentTerm" class="flex items-start space-x-3 p-4 px-6">
                            <div>
                              <vs-radio v-model="selectedPayment" :vs-value="card.value
                                " />
                            </div>
                            <div>
                              <p class="font-medium">{{ card.text }}</p>
                              <p class="text-sm mb-1">You will receive an email with an invoice.</p>
                            </div>
                          </div>
                          <div v-else-if="radioButtonEnabled(card.type)" class="flex items-start space-x-3 p-4 px-6">
                            <div v-if="radioButtonEnabled(card.type)">
                              <vs-radio v-model="selectedPayment" :vs-value="card.cardId ? card.cardId : card.paymentMethodId
                                " />
                            </div>
                            <div v-if="card.type === 'CreditCard' && clinicPaymentOptions.some(e => e === 'creditCard')"
                              class="flex-1 leading-none flex flex-col space-y-2">
                              <p class="font-medium">{{ card.brand }}</p>
                              <p class="text-sm mb-1">XXXX XXXX XXXX {{ card.last4 }}</p>
                              <p class="text-sm mb-1">
                                {{ card.exp_month }} /
                                {{ card.exp_year }}
                              </p>
                            </div>
                            <div v-if="card.type !== 'CreditCard' && clinicPaymentOptions.some(e => e === 'DebitCard')"
                              class="flex-1 leading-none flex flex-col space-y-2">
                              <p class="font-medium">{{ card.accountHolderName }}</p>
                              <p class="text-sm mb-1">{{ card.accountHolderEmail }}</p>
                              <p class="text-sm mb-1">
                                {{ card.bsb }} /
                                {{ card.accountNumber }}
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="border p-6 mt-5" style="border-top: solid 1px #e0e0e0" v-if="isCCorDebitEnabled">

                      <vs-button color="primary" icon="add" class="w-full lg:w-auto" icon-after @click="openPopup"
                        v-if="totalPrice <= 29999 * 100 && canCheckout">
                        Add Payment Method
                      </vs-button>

                      <div class="flex flex-row p-3 rounded-lg text-white cursor-pointer"
                        style="width: 230px; background-color: #a0a0a0" v-if="totalPrice > 29999 * 100 || !canCheckout">
                        <plus-icon size="1.5x" class="custom-class"></plus-icon>
                        <div class="pl-4 pt-1">Add Payment Method</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form-wizard>
          </div>
        </div>
        <div class="w-full lg:w-2/5 lg:px-4 md:px-4">
          <div v-if="active === 1">
            <div v-if="hasOrganization" class="w-full bg-white p-5 rounded-lg mb-5 shadow">
              <SwapClinic :title="`This cart is for:`" />
            </div>
            <vx-card>
              <div v-if="getTotalSavings > 0" class="flex items-center justify-between mb-3">
                <span class=" text-lg ">Don’t pay list price</span>
                <span class=" text-2xl line-through">{{ currency }} ${{ totalListPrice | formatDecimals }}</span>
              </div>

              <div v-if="getTotalSavings > 0 || totalHistorySavings > 0"
                class="savings p-3 flex items-center justify-center mb-3">
                <div>
                  <img class=" w-8 mr-4 fill-current text-primary" :src="require('../../../../public/Group-543.svg')" />
                </div>
                <div class=" font-medium text-xl">
                  You’re saving ${{ getTotalSavings + totalHistorySavings | formatDecimals }} by using Fresh Clinics
                </div>
              </div>

              <vs-divider />

              <div class="flex items-center justify-between">
                <span class="">Sub Total</span>
                <span class=" price-text text-2xl">{{ currency }} ${{ subTotal - getTotalSavings | formatDecimals
                  }}</span>
              </div>
              <vs-divider />
              <CheckoutVoucher :voucherData="voucher" @updateVoucher="voucher = $event"
                :totalBalance="balanceBeforeVoucher" :totalQty="cartItemsQty" :retoolDiscounts="discountsArray"
                :shippingDiscounts="deliveryFees" />

              <vs-divider />
              <div class="flex items-center justify-between mb-2">
                <span class=" font-bold text-sm delivery-text">DISCOUNT</span>
              </div>

              <div class="flex justify-between mb-2" v-for="discountItem in discountsArray" :key="discountItem._id">
                <span class="delivery-text">
                  {{ discountItem.rule.discount_title }}</span>
                <span class="price-text">{{ currency }} ${{
                  discountItem.totalDiscount | formatDecimals
                  }}
                </span>
              </div>

              <vs-divider />
              <div class="flex items-center justify-between mb-2">
                <span class=" font-bold text-sm delivery-text">DELIVERY</span>
              </div>

              <div class="flex justify-between mb-2" v-for="freeShippingItem in freeShippingArray"
                :key="freeShippingItem._id">
                <span class="delivery-text">
                  {{ freeShippingItem.free_shipping_name }}</span>
                <span class="price-text">{{ currency }} $-{{
                  freeShippingItem.totalDiscount | formatDecimals
                  }}
                </span>
              </div>

              <div class="flex justify-between mb-2" v-for="(item, index) in deliveryFees" :key="index">
                <span class="delivery-text">{{ item.supplierName }}</span>
                <span class="price-text">{{ currency }} ${{ item.amount | formatDecimals }}</span>
              </div>
              <div class="flex justify-between mb-2">
                <span class="delivery-text font-semibold">Total delivery fee</span>
                <span class="price-text text-2xl">{{ currency }} ${{ deliveryCharge | formatDecimals }}</span>
              </div>

              <vs-divider />

              <div class="flex items-center justify-between mb-2">
                <span class=" font-bold text-sm delivery-text">FEES</span>
              </div>

              <div class="flex justify-between mb-2" v-if="showCreditCardPaymentCharge">
                <span class="text-grey w-3/5">
                  2% Card Transaction Fee
                </span>
                <span class="text-success">{{ currency }} ${{
                  creditCardTransactionFee | formatDecimals
                  }}</span>
              </div>
              <div class="flex justify-between mb-2" v-if="!isUS">
                <span class="delivery-text">GST (10%)</span>
                <span>{{ currency }} ${{ gstTotal | formatDecimals }}</span>
              </div>
              <div class="flex justify-between mb-2" v-if="isUS">
                <span class="price-text">Taxes</span>
                <span>{{ currency }} ${{ tax | formatDecimals }}</span>
              </div>

              <vs-divider v-if="voucher" />
              <div class="flex items-center justify-between mb-2" v-if="voucher">
                <span class=" font-bold text-sm delivery-text">VOUCHER DISCOUNT</span>
              </div>
              <div class="flex justify-between mb-2" v-if="voucher">
                <span class="flex items-center gap-2 price-text">
                  <feather-icon icon='TagIcon' svgClasses='h-4 w-4' />{{ voucher.discount_title }}
                </span>
                <span>&minus;{{ currency }} ${{ voucherPrice | formatDecimals }}</span>
              </div>

              <vs-divider />

              <div class="flex justify-between items-center mb-3">
                <span>Total</span>
                <span class=" text-4xl">{{ currency }} ${{ totalPrice | formatDecimals }}</span>
              </div>

              <vs-divider />

              <div v-if="inactiveCartItems.length > 0" class="flex py-5">
                <feather-icon icon='AlertTriangleIcon' svgClasses='h-7 w-7' class='ml-1 text-yellow-600 pr-2' />
                <span class=" text-sm">Please remove inactive products to proceed!</span>
              </div>
              <vs-button class="w-full font-bold"
                :disabled="!validateForm || cartItems.length <= 0 || disableNext || inactiveCartItems.length > 0"
                @click="onClick(2)" style="height: 84px;">NEXT
              </vs-button>
            </vx-card>

            <vx-card>
              <p class="font-semibold mb-3">Add Notes</p>
              <vs-textarea data-vv-as="field" name="notes" :maxlength="max" v-model="shippingDetails.notes"
                class="w-full h-20 mt-5"></vs-textarea>
            </vx-card>
          </div>
          <div v-if="active === 2">
            <div v-if="hasOrganization" class="w-full bg-white p-5 rounded-lg mb-5 shadow">
              <SwapClinic :title="`This cart is for:`" />
            </div>
            <vx-card :title="shippingDetails.clinicName">
              <div>
                <p>{{ shippingDetails.address.displayAddress }}</p>
              </div>

              <vs-divider />
              <vs-button class="w-full font-bold" :disabled="deliveryButtonDisabled || disableNext" @click="onClick(3)"
                style="height: 84px;">Deliver to this Address
              </vs-button>

            </vx-card>
            <vx-card>
              <p class="font-semibold mb-3">Order Notes</p>
              <p class="font-regular mb-3 break-all">
                {{ shippingDetails.notes }}
              </p>
            </vx-card>
          </div>
          <div v-if="active === 3">
            <div v-if="hasOrganization" class="w-full bg-white p-5 rounded-lg mb-5 shadow">
              <SwapClinic :title="`This cart is for:`" />
            </div>
            <vx-card>
              <p class="font-semibold mb-3" style="font-size: 16px">
                Price Details
              </p>
              <p style="color: #999999">
                Please note there is a maximum purchase of $30,000 {{ currency }}
              </p>
              <div style="
                  width: 100%;
                  margin: 15px 0;
                  border-bottom: solid 1px #e0e0e0;
                "></div>

              <div class="flex items-center justify-between mb-2">
                <span class="">Sub Total</span>
                <!-- <span class=" price-text text-2xl">{{ currency }} ${{ subTotal | formatDecimals }}</span> -->
                <span class=" price-text text-2xl">{{ currency }} ${{ subTotal - getTotalSavings | formatDecimals
                  }}</span>
              </div>

              <vs-divider />
              <div class="flex items-center justify-between mb-2">
                <span class=" font-bold text-sm delivery-text">DISCOUNT</span>
              </div>

              <div class="flex justify-between mb-2" v-for="discountItem in discountsArray" :key="discountItem._id">
                <span class="delivery-text">
                  {{ discountItem.rule.discount_title }}</span>
                <span class="price-text">{{ currency }} ${{
                  discountItem.totalDiscount | formatDecimals
                  }}
                </span>
              </div>

              <vs-divider />
              <div class="flex items-center justify-between mb-2">
                <span class=" font-bold text-sm delivery-text">DELIVERY</span>
              </div>

              <div class="flex justify-between mb-2" v-for="freeShippingItem in freeShippingArray"
                :key="freeShippingItem._id">
                <span class="text-grey">
                  {{ freeShippingItem.free_shipping_name }}</span>
                <span class="text-success">{{ currency }} $-{{
                  freeShippingItem.totalDiscount | formatDecimals
                  }}
                </span>
              </div>

              <div class="flex justify-between mb-2" v-for="(item, index) in deliveryFees" :key="index">
                <span class="delivery-text">{{ item.supplierName }}</span>
                <span class="price-text">{{ currency }} ${{ item.amount | formatDecimals }}</span>
              </div>
              <div class="flex justify-between mb-2">
                <span class="delivery-text font-semibold">Total delivery fee</span>
                <span class="price-text text-2xl">{{ currency }} ${{ deliveryCharge | formatDecimals }}</span>
              </div>

              <vs-divider />

              <div class="flex justify-between mb-2" v-if="showCreditCardPaymentCharge">
                <span class="text-grey">2% Card Transaction Fee</span>
                <span class="text-success">{{ currency }} ${{
                  creditCardTransactionFee | formatDecimals
                  }}</span>
              </div>
              <div class="flex justify-between mb-2" v-if="!isUS">
                <span class="delivery-text">GST (10%)</span>
                <span>{{ currency }} ${{ gstTotal | formatDecimals }}</span>
              </div>
              <div class="flex justify-between mb-2" v-if="!isUS && voucher">
                <div class="flex items-center gap-2 text-grey">
                  <feather-icon icon='TagIcon' svgClasses='h-4 w-4' />{{ voucher.discount_title }}
                </div>
                <span>&minus;{{ currency }} ${{ voucherPrice | formatDecimals }}</span>
              </div>
              <div class="flex justify-between mb-2" v-if="isUS">
                <span class="price-text">Taxes</span>
                <span>{{ currency }} ${{ tax | formatDecimals }}</span>
              </div>
              <vs-divider />

              <div class="flex justify-between mb-3 items-center">
                <span>Total</span>
                <span class=" text-4xl">{{ currency }} ${{ totalPrice | formatDecimals }}</span>
              </div>
              <vs-divider />
              <div class="flex justify-between font-semibold mb-3" v-if="isLoggedInSuperAdmin">
                <span>Send to Sage?</span>
                <vs-switch name="sendToSage" v-model="sendToSage" />
              </div>
              <vs-button v-if="(paymentType || selectedPayment) && canCheckout" class="w-full font-bold"
                style="height: 84px;" @click="sendToServer({ amount: totalPrice })"
                :disabled="totalPrice > 29999 * 100 || !meetOpeningOrder || !hasPaymentTermSetup || disableNext || disabledClinic">
                <span v-if="paymentDetails && paymentDetails.isPaymentTerm">Confirm</span>
                <span v-else>Confirm And Pay</span>
              </vs-button>
              <div v-if="(!paymentType && !selectedPayment) || !canCheckout"
                class="w-full p-3 text-center rounded text-white" style="background-color: #a0a0a0">
                <p v-if="hasOustanding">
                  Sorry! Your account has an outstanding product payment. Please
                  complete your payment before continuing.<br />
                  <span style="
                      padding-bottom: 2px;
                      border-bottom: solid 1px white;
                      cursor: pointer;
                    " @click="goToOrders()">Pay Now</span>
                </p>
                <p v-if="!hasOustanding">Confirm Payment</p>
              </div>
            </vx-card>
            <vx-card>
              <p class="font-semibold mb-3">Order Notes</p>
              <p class="font-regular mb-3 break-all">
                {{ shippingDetails.notes }}
              </p>
            </vx-card>
          </div>
        </div>
      </div>
      <vs-popup class="holamundo" title="Outstanding Payment" :active.sync="popupActive2">
        <div class="flex flex-no-wrap">
          <div class="w-1/6 flex items-center justify-center">
            <vs-icon icon="clear" size="medium" bg="#f9d6d6" color="#b21f1e" round></vs-icon>
          </div>
          <div class="w-5/6">
            <p>
              Sorry! Your account has an outstanding product payment. Please
              complete your payment before continuing.
              <br />Need help? Call 1300 375 646
            </p>
          </div>
        </div>
        <div class="flex justify-between mt-5">
          <vs-button class="mr-4 mb-4" @click="popupActive2 = false">
            Close
          </vs-button>
          <vs-button class="mr-4 mb-4" @click="goToOrders()">
            Go to payment
          </vs-button>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapActions, mapMutations } from "vuex";
import _ from "lodash";
const CheckoutListView = () => import("./CheckoutListView.vue");
import StripeElements from "../../components/order-center/StripeElements";
import ClinicBankDetailSetup from "../../clinic/bankDetails/Setup";
import BankSetup from "../../components/order-center/BankSetup";
import Tabs from "../../../layouts/components/tabs/tabs.vue";
import { PlusIcon, XIcon } from "vue-feather-icons";
import SwapClinic from "../../components/shop/clinic.vue";
import StoreHelper from "../../../mixins/Store.vue";
import CheckoutHelper from "../../../mixins/Checkout.vue";
import { loadStripeSdk } from '../../components/order-center/load-checkout';
import CheckoutVoucher from "../../components/voucher/CheckoutVoucher.vue"

// Utils
import {
  trackEcommerceEvent,
  getCurrency,
  formatPriceValue,
  BEGIN_CHECKOUT_EVENT,
  VIEW_CART_EVENT,
  PURCHASE,
  ADD_PAYMENT_INFO,
  ADD_SHIPPING_INFO
} from '../../../utils/gtag'

export default {
  name: "Checkout",
  inject: ["getRoleRouteName"],
  mixins: [StoreHelper, CheckoutHelper],
  data() {
    return {
      showInactive: false,
      stripe: null,
      hasOrganization: false,
      stripePublishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      saveCreditCard: true,
      selectedPayment: null,
      isSavedAddress: false,
      selectedMode: null,
      cardDetails: [],
      defaultCard: null,
      financialDetails: null,
      isNewClinicBankAccount: true,
      isAccessPaymentTerms: false,
      paymentMode: null,
      bankMode: null,
      popupActive: false,
      role: null,
      shippingDetails: {
        clinicName: "",
        address: {},
        email: "",
        notes: "",
      },
      card: {
        number: "",
        cvc: "",
        exp: "",
      },
      isCreditCard: false,
      max: 200,
      // TAB 3
      paymentType: null,
      popupActive2: false,
      // cvv: '',
      active: 1,
      operations: {
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
      },
      deliveryButtonDisabled: true,
      isUS: process.env.VUE_APP_REGION !== "AU",
      isAU: process.env.VUE_APP_REGION === "AU",
      currency: process.env.VUE_APP_CURRENCY,
      region: process.env.VUE_APP_REGION,
      paymentMethods: [],
      orgPaymentMethods: [],
      tax: 0,
      showCreditCard: false,
      showBankAccount: false,
      canCheckout: true,
      hasOustanding: false,
      showCreditCardPaymentCharge: false,
      paymentDetails: null,
      clinicId: sessionStorage.getItem("doctorClinicSelectedClinic"),
      deliveryFees: [],
      cartItems: [],
      inactiveCartItems: [],
      disableInput: false,
      isCreditCardFeeExempt: false,
      isMobileView: null,
      clinicPaymentOptions: [],
      sendToSage: true,
      orgOwnerEmail: "",
      voucher: null,
      voucherPrice: 0,
      totalHistorySavings: 0,
      isDoctorStore: false,

      tracked: false // Use to check whether the event has been tracked since cartItems from store are being watched multiple times.
    };
  },
  computed: {
    couponsList() {
      return this.discountsArray.length
        ? this.discountsArray.map(discount => discount.rule.discount_title).join(", ")
        : null;
    },

    limitedInactiveItems() {
      return this.showInactive ? this.inactiveCartItems : this.inactiveCartItems.slice(0, 1);
    },
    checkDeviceType() {
      const isMobileApp = localStorage.getItem("iframe-view");
      if (isMobileApp) {
        return {
          "type": "mobile"
        };
      } else {
        if (window.innerWidth <= 640) { // Breakpoint for mobile view
          return {
            "type": "mobileweb"
          };
        }
        return {
          "type": "web"
        };
      }
    },
    cartItemsQty() {
      let items = this.$store.state.eCommerce.cartItems;
      let count = 0;

      items.map(item => {
        count += item.quantity
      })

      return count
    },
    hasPaymentTermSetup() {
      const hasCCorDebit = this.clinicPaymentOptions.length && this.clinicPaymentOptions.some(e => ["creditcard", "debitcard"].includes(e.toLowerCase()))
      if (!this.paymentDetails) return false;
      if (this.paymentDetails.isPaymentTerm) return true
      return (this.paymentDetails && ["CreditCard", "BankAccount"].includes(this.paymentDetails.type)) && hasCCorDebit
    },
    isCCorDebitEnabled() {
      return this.clinicPaymentOptions.length && this.clinicPaymentOptions.some(e => ["creditcard", "debitcard"].includes(e.toLowerCase()))
    },
    disabledClinic() {
      const state = this.$store.state;
      return !state.clinicId && !state.operations
    },

    meetOpeningOrder() {
      let isMeet = true;
      if (!this.$isNZRegion()) {
        const matchedSuppliers = {};
        const minimumValue = this.minimumRequirement && Object.keys(this.minimumRequirement).length ? +this.minimumRequirement.value : 0;
        this.cartItems.map((item) => {
          const { inventory } = item
          const isSameSupplier = this.openingOrderSuppliers.find((i) => i === inventory.managedBy)
          if (!item.openingOrder && isSameSupplier) {
            if (matchedSuppliers[inventory.managedBy]) {
              matchedSuppliers[inventory.managedBy] = matchedSuppliers[inventory.managedBy] + (item.quantity * item.price)
            } else {
              matchedSuppliers[inventory.managedBy] = item.quantity * item.price
            }
          }
        })

        Object.keys(matchedSuppliers).map((key) => {
          if (matchedSuppliers[key] < minimumValue) isMeet = false;
        })
      }
      return isMeet;
    },
    totalPriceDiscountAndDeliveryCharge() {
      /**
       * If voucher is applied, 
       * remove delivery charge from computation
       * since it's already computed in the subTotal
       */
      if (this.voucher && !this.voucher.applyAfterGST) {
        return (
          this.subTotal -
          this.totalDiscounts
        );
      }

      return (
        this.subTotal -
        this.totalDiscounts +
        this.deliveryCharge
      );
    },
    balanceBeforeVoucher() {
      const creditCardFee = this.creditCardTransactionFee;
      let total = this.totalPriceDiscountAndDeliveryCharge + creditCardFee;
      if (this.region === "AU") {
        total = total + this.gstTotal;
      }
      return Math.round(total);
    },
    totalPrice() {
      const creditCardFee = this.creditCardTransactionFee;
      let total = this.totalPriceDiscountAndDeliveryCharge + creditCardFee;
      if (this.region === "AU") {
        total = total + this.gstTotal;
      }
      if (this.voucher && this.voucher.applyAfterGST) {
        total = total - this.voucherPrice;
        if (total < 0) {
          total = 0.00;
        }
      }
      return Math.round(total);
    },
    gstTotal() {
      const voucherGSTdeductions = this.voucher && this.voucher.applyAfterGST ? 0 : this.voucherPrice;
      const subtotal = this.getTaxableSubtotal() - voucherGSTdeductions;
      const ccGST = ((this.creditCardTransactionFee * 0.1) / 100).toFixed(2) * 100;
      const cartGST = ((((subtotal + this.deliveryCharge)) * 0.1) / 100).toFixed(2) * 100;
      return cartGST + ccGST;
    },
    getActualSubTotal() {
      return 0;
    },
    freeShippingTotal() {
      return _.sum(
        this.freeShippingArray.map((freeshipping) => freeshipping.totalDiscount)
      );
    },
    deliveryCharge() {
      return this.getDeliveryCharge() - this.freeShippingTotal;
    },
    creditCardTransactionFee() {
      let searchResult = this.paymentMethods.filter(
        ({ paymentMethodId, type }) =>
          paymentMethodId === this.selectedPayment && type === "CreditCard"
      );

      if (searchResult.length <= 0) {
        searchResult = this.paymentMethods.filter(
          ({ cardId, type }) =>
            cardId === this.selectedPayment && type === "CreditCard"
        );
      }

      if (searchResult.length && searchResult[0].type === "CreditCard" && !this.isCreditCardFeeExempt) {
        const creditCardCharge = (this.totalPriceDiscountAndDeliveryCharge * 2) / 100;
        return creditCardCharge;
      }
      return 0;
    },
    discountTotal() {
      if (this.isAU) {
        return (
          this.subTotal +
          this.deliveryCharge +
          this.creditCardTransactionFee +
          this.gstTotal -
          this.totalPrice
        );
      } else {
        return 0;
      }
    },
    subTotal() {
      let temp = this.cartSubTotal;
      if (this.voucher && !this.voucher.applyAfterGST) {
        temp = (temp + this.deliveryCharge) - this.voucherPrice;
        if (temp < 0) {
          temp = 0.00;
        }
      }
      return temp;
    },
    totalListPrice() {
      let totalListPrice = 0;
      this.cartItems.map(item => {
        if (this.isDiscounted(item)) {
          totalListPrice = totalListPrice + (((item.listPrice) * item.quantity))
        }
        else {
          totalListPrice = totalListPrice + (((item.price) * item.quantity))
        }
      })
      return totalListPrice;
    },
    getTotalSavings() {
      let totalDiscount = 0;

      this.discountsArray.map(discount => {
        totalDiscount = totalDiscount + discount.totalDiscount
      })
      return totalDiscount
    },
    isInWishList() {
      return (itemId) => this.$store.getters["eCommerce/isInWishList"](itemId);
    },
    validateForm() {
      return !this.errors.any();
    }
  },
  async created() {
    this.getClinicPaymentDetails();
    this.getClinicDetails();
    this.shippingDetails = await this.getUserDetail();
    this.isUS = process.env.VUE_APP_REGION === "US";

    if (this.$store.state.clinic && this.$store.state.clinic.length) {
      this.showCreditCard = this.$store.state.clinic[0].paymentTypes.includes("creditCard");
      this.showBankAccount = this.$store.state.clinic[0].paymentTypes.includes("DebitCard");
    }

    this.canCheckoutChecker();
    if (this.isUS) this.getUSTax();
    const orderId = localStorage.getItem("orderId")
    if (orderId) {
      this.reOrderItems(orderId)
    }
    const { data } = await this.fetchTotalSavings(this.clinicId);
    this.totalHistorySavings = data.data
  },
  watch: {
    voucher: {
      handler(val) {
        if (val) {
          if (val.discount_type === "fix") {
            this.voucherPrice = val.discount_value * 100;
          } else {
            if (val.applyAfterGST) {
              this.voucherPrice = (val.discount_value / 100) * this.totalPrice;
            } else {
              this.voucherPrice = (val.discount_value / 100) * (this.subTotal - this.getTotalSavings);
            }
          }
        } else {
          this.voucherPrice = 0;
        }
      },
      deep: true,
    },
    "$store.state.eCommerce.inactiveCartItems": {
      handler(val) {
        let inactiveCartItems = JSON.parse(JSON.stringify(val.slice().reverse()))
        this.inactiveCartItems = inactiveCartItems
      },
      immediate: true
    },
    "$store.state.eCommerce.cartItems": {
      handler(val, old) {
        let cartItems = JSON.parse(JSON.stringify(val.slice().reverse()))

        cartItems = cartItems.map((item) => {
          let superAdminPrice = item.price;
          let superAdminDiscountPrice = 0;

          if (item.hasOwnProperty('superAdminPrice')) superAdminPrice = item.superAdminPrice
          if (item.hasOwnProperty('superAdminDiscountPrice')) {
            superAdminDiscountPrice = item.superAdminDiscountPrice
            item.superAdminDiscountPriceFrom = 'db'
          }

          if (this.isLoggedInSuperAdmin) {
            item.superAdminPrice = superAdminPrice / 100
            item.superAdminDiscountPrice = superAdminDiscountPrice / 100
          }

          return item
        })

        this.cartItems = cartItems;

        // Track the ecommerce events only once when cartItems are fetched from 
        // Vue store. This is to prevent multiple tracking of the same event.
        if (val.length > 0 && !this.tracked) {
          trackEcommerceEvent(VIEW_CART_EVENT, {
            currency: getCurrency(),
            value: formatPriceValue(this.cartSubTotal),
            items: this.cartItems.map((item) => ({
              item_id: item._id,
              item_name: item.name,
              price: formatPriceValue(item.price),
              quantity: item.quantity,
            })),
          });

          trackEcommerceEvent(BEGIN_CHECKOUT_EVENT, {
            currency: getCurrency(),
            value: formatPriceValue(this.cartSubTotal),
            coupon: this.couponsList,
            items: this.cartItems.map((item) => ({
              item_id: item._id,
              item_name: item.name,
              price: formatPriceValue(item.price),
              quantity: item.quantity,
            })),
          });

          this.tracked = true;
        }
      },
      immediate: true
    },
    gstTotal: function (curr, prev) {
      if (curr !== prev) {
        // this.getCartItems();
      }
    },
    creditCardTransactionFee: function (val) {
      this.showCreditCardPaymentCharge = val > 0;
    },
    selectedPayment: function (val) {
      this.populatePaymentDetails(val);
    },
    operations: {
      deep: true,
      handler(value) {
        const isDisabled = Object.keys(value).filter(
          (k) => !value[k] || value[k] === ""
        );
        if (isDisabled.length <= 0) {
          this.deliveryButtonDisabled = false;
        } else {
          this.deliveryButtonDisabled = true;
        }
      },
    },
    popupActive: function (val) {
      if (!val) {
        this.populatePaymentDetails(this.selectedPayment);
      }
    },
    "$store.state.clinicId": function () {
      // reset selected/default paymentDetails
      this.paymentDetails = null
      this.updateHoursOfOPeration(this.$store.state.operations);
      this.getClinicPaymentDetails();
      this.getClinicDetails(this.$store.state.clinicId);
      this.canCheckoutChecker();
      if (this.$store.state.clinic && this.$store.state.clinic.length) {
        this.shippingDetails = this.$store.state.clinic[0];

        this.showCreditCard =
          this.$store.state.clinic[0].paymentTypes.includes("creditCard");
        this.showBankAccount =
          this.$store.state.clinic[0].paymentTypes.includes("DebitCard");
      }

      if (this.cartItems.length && (this.active === 3 || this.active === 2)) {
        this.active = 1;
      }
      this.isUS = process.env.VUE_APP_REGION === "US";
      if (this.isUS) this.getUSTax();
    },
  },
  methods: {
    ...mapActions("ecommerce", [
      "getCartItemsFromApi",
      "paymentForCartItems",
      "fetchFailedPayments",
      "createMinimumOrder",
      "orderAgain",
      "fetchProductDetail"
    ]),
    ...mapActions("clinic", ["addNewPaymentMethod", "fetchClinicsDetail", "fetchTotalSavings"]),
    ...mapActions("general", [
      "fetchUserDetails",
      "fetchCardDetails",
      "fetchCardDetailsList",
      "fetchClinicPaymentDetails",
      "taxJarCalculateOrderTax",
      "setDefaultPaymentMethod",
    ]),
    ...mapMutations("ecommerce", ["REMOVE_ALL_ITEMS_IN_CART", "UPDATE_SHIPPING_FEE_CHECKER"]),
    ...mapActions("storeProducts", ["fetchStoreLimitation"]),
    ...mapActions("storeAlertNotification", ["fetchCheckoutStoreAlertsBanner"]),

    toggleInactiveItems() {
      this.showInactive = !this.showInactive;
    },

    removeAllInactive() {
      //TODO: Remove all inactives
      this.inactiveCartItems.forEach(item => {
        this.$store.dispatch("eCommerce/removeInactiveItemFromCart", item);
      });
    },

    radioButtonEnabled(type) {
      const formattedType = {
        "CreditCard": "creditCard",
        "BankAccount": "DebitCard",
      }

      return this.clinicPaymentOptions.length && this.clinicPaymentOptions.some(e => e === formattedType[type])
    },
    async getClinicDetails() {
      try {
        const { data } = await this.fetchClinicsDetail(sessionStorage.getItem("doctorClinicSelectedClinic"));
        this.isDoctorStore = data.data.isDoctorStore

        /*
          Set org owner email if order was made by super-admin
          Pass to bank-setup component
        */
        if (this.$store.state.AppActiveUser.userType === 'superAdmin') {
          const { orgOwner, storeInvoiceEmail, invoiceEmail } = data.data;
          let orgOwnerEmail = storeInvoiceEmail

          if (orgOwner && !storeInvoiceEmail) {
            orgOwnerEmail = orgOwner.details[0].email || ""
          }
          if (!orgOwner) {
            orgOwnerEmail = invoiceEmail
          }
          this.orgOwnerEmail = orgOwnerEmail
        }

        this.isCreditCardFeeExempt = data.data.isCreditCardFeeExempt;
        this.clinicPaymentOptions = data.data.paymentTypes;
      } catch (error) {
        console.log(`Fetching clinic details: ${error}`)
      }
    },
    async canCheckoutChecker() {
      this.canCheckout = true;
      this.hasOustanding = false;
    },
    closePopup() {
      this.getClinicPaymentDetails();
    },
    populatePaymentDetails(val) {
      let searchResult = this.paymentMethods.filter(
        ({ paymentMethodId, type }) =>
          paymentMethodId === val && type === "CreditCard"
      );

      if (searchResult.length <= 0) {
        searchResult = this.paymentMethods.filter(
          ({ cardId, type }) => cardId === val && type === "CreditCard"
        );
        this.paymentType = 'CreditCard';
      }

      if (searchResult.length <= 0) {
        searchResult = this.paymentMethods.filter(
          ({ paymentMethodId, type }) =>
            paymentMethodId === val && type === "BankAccount"
        );
        this.paymentType = 'BankAccount';
      }
      if (searchResult.length <= 0) {
        searchResult = this.paymentMethods.filter(
          ({ value, isPaymentTerm }) =>
            value === val && isPaymentTerm
        );
      }

      if (searchResult.length) {
        this.paymentDetails = { ...searchResult[0] };
      }
    },
    getDeliveryCharge() {
      const items = this.$store.getters["eCommerce/items"];
      const {
        total,
        appliedCustomDeliveryFee
      } = this.$store.getters["eCommerce/delivery"];

      this.deliveryFees = []

      items.map((val) => {
        const filter = this.deliveryFees.filter(({ supplierId }) =>
          val.deliveryFees
            .map((fee) => fee.supplierId.toString())
            .includes(supplierId.toString())
        );
        if (!filter.length) {
          // apply custom delivery fee
          const deliveries = val.deliveryFees.map(i => {
            const amount = appliedCustomDeliveryFee[i.supplierId]
            if (amount) i.amount = amount
            return i
          })

          this.deliveryFees.push(...deliveries);
        }
      });

      return total
    },
    getTaxableSubtotal() {
      let subtotal = 0;
      this.cartItems.map((item) => {
        if (item.isTaxable) {
          let price = item.price;

          if (this.isLoggedInSuperAdmin && item.hasOwnProperty('superAdminPrice')) price = item.superAdminPrice * 100

          if (this.isDiscounted(item)) {
            const rule = this.discountsArray.filter(discount => discount.productIds.some(id => id === item.productStoreId))[0].rule;

            // has bulk discount rule & clinic discount, bulk discount < clinic discount
            // if (rule && this.hasClinicDiscount(item.productStoreId) && item.originalPrice) price = item.originalPrice

            // discount is List Price, use listPrice
            if (rule.discount_price && rule.discount_price === 'List Price') price = item.listPrice;
            if (rule.discount_price && rule.discount_price === 'Doctor Price') price = item.doctorPrice;

            // if key superAdminDiscountPrice exists, override discount price
            if (this.isLoggedInSuperAdmin && item.hasOwnProperty('superAdminDiscountPrice')) price = item.superAdminDiscountPrice * 100

            if (rule.discount_price && rule.discount_price === 'List Price' && rule.discount_type === 'percentage') {
              price = price - (price * (rule.discount_value / 100));
            } else if (rule.discount_price && rule.discount_price === 'List Price' && rule.discount_type === 'fix') {
              price = price - (rule.itemDiscount * 100)
            } else if (rule.discount_type === 'percentage') {
              price = price - (price * (rule.discount_value / 100));
            } else {
              price = price - (rule.itemDiscount * 100);
            }
          }
          subtotal += price * item.quantity;
        }
      });
      return subtotal;
    },
    isDiscounted(item) {
      return this.discountsArray.some(discount => discount.productIds.some(id => id === item.productStoreId));
    },
    getUSTax() {
      const data = {
        from_country: "US",
        from_zip: this.shippingDetails.address.postCode,
        from_state: this.shippingDetails.address.state,
        from_city: this.shippingDetails.address.suburb,
        from_street: this.shippingDetails.address.displayAddress,
        amount: this.totalPrice / Math.pow(10, 2),
        shipping: "0",
        to_country: "US",
        to_zip: this.shippingDetails.address.postCode,
        to_state: this.shippingDetails.address.state,
        to_city: this.shippingDetails.address.suburb,
        to_street: this.shippingDetails.address.displayAddress,
      };
      this.taxJarCalculateOrderTax(data)
        .then((res) => {
          this.tax = res.data.data.tax.amount_to_collect * 100;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setDefaultPaymentType(paymentMethods) {
      const defaultValue = paymentMethods.filter(({ isDefault }) => isDefault);
      const formattedType = {
        "CreditCard": "creditCard",
        "BankAccount": "DebitCard",
      }
      const isNotHidden = this.clinicPaymentOptions.some(e => e === formattedType[defaultValue[0].type]);
      if (defaultValue.length && isNotHidden) {
        this.selectedPayment = defaultValue[0].cardId
          ? defaultValue[0].cardId
          : defaultValue[0].paymentMethodId;
      } else {
        /*
          check if clinic payment methods enabled
          it's not default and is not payment term
        */
        const otherPaymentMethods = paymentMethods.filter(e => !e.isDefault && !e.isPaymentTerm && this.clinicPaymentOptions.some(ee => ee === formattedType[e.type]));
        if (otherPaymentMethods.length) {
          this.selectedPayment = otherPaymentMethods[0].cardId
            ? otherPaymentMethods[0].cardId
            : otherPaymentMethods[0].paymentMethodId;
        }
      }
    },
    getClinicPaymentDetails() {
      if (sessionStorage.getItem("doctorClinicSelectedClinic")) {
        this.fetchClinicPaymentDetails(
          sessionStorage.getItem("doctorClinicSelectedClinic")
        )
          .then((res) => {
            this.paymentMethods = res.data.data;
            this.setDefaultPaymentType(res.data.data);
          })
          .catch((err) => {
            // capture if no org
            if (err && err.status === 400) {
              this.$vs.notify({
                title: "Error",
                text: "No Organisation assigned. Can't checkout/order.",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });
            }
            console.log(err);
          });
      }
    },
    updateHoursOfOPeration(val) {
      if (val && Object.keys(val).length) {
        this.operations = val;
      }
    },
    async onClick(val) {
      if (this.cartItems.length <= 0 && val === 2) {
        this.active = 1;
      } else if (val === 3) {
        /** Validate operation hours  */
        if (!this.deliveryButtonDisabled) {
          this.active = val;

          trackEcommerceEvent(ADD_PAYMENT_INFO, {
            currency: getCurrency(),
            value: formatPriceValue(this.cartSubTotal),
            coupon: this.couponsList,
            payment_type: this.paymentType,
            items: this.cartItems.map((item) => ({
              item_id: item._id,
              item_name: item.name,
              price: formatPriceValue(item.price),
              quantity: item.quantity,
            })),
          });
        }
      } else if (this.cartItems.length > 0 && val === 2) {
        try {
          const validationPromises = this.cartItems.map(ci =>
            this.fetch_item_details(ci.productStoreId, ci.quantity, val)
          );

          const errorArrays = await Promise.all(validationPromises);

          const combinedErrors = [].concat(...errorArrays);
          if (combinedErrors.length === 0) {
            this.active = val;
            trackEcommerceEvent(ADD_SHIPPING_INFO, {
              currency: getCurrency(),
              value: formatPriceValue(this.cartSubTotal),
              coupon: this.couponsList,
              items: this.cartItems.map((item) => ({ item_id: item._id, item_name: item.name, price: formatPriceValue(item.price), quantity: item.quantity, })),
            });
          }
        } catch (error) {
          console.log('error', error)
        }
      }
      else if (val !== 3) {
        this.active = val;
      }
    },

    clinicBankPaymentSuccess(val) {
      this.$vs.loading.close();
      this.popupActive = false;
      this.getClinicPaymentDetails();
    },
    openPopup() {
      this.popupActive = true;
      this.paymentType = "CreditCard";
      this.selectedPayment = null;
      this.paymentDetails = null;

      const payment = this.clinicPaymentOptions

      if (payment.includes('creditCard') && payment.includes('DebitCard')) {
        this.showBankAccount = true;
        this.showCreditCard = true;
      } else if (payment.includes('creditCard') && !payment.includes('DebitCard')) {
        this.showBankAccount = false;
        this.showCreditCard = true;
      } else if (!payment.includes('creditCard') && payment.includes('DebitCard')) {
        this.showBankAccount = true;
        this.showCreditCard = false;
      } else {
        this.showBankAccount = false;
        this.showCreditCard = false;
      }

    },
    goToOrders() {
      this.popupActive2 = false;
      setTimeout(() => {
        this.$router.push({ name: this.getRoleRouteName("OrderList") });
      }, 200);
    },
    getCartItems() {
      this.$vs.loading();
      this.getCartItemsFromApi(
        sessionStorage.getItem("doctorClinicSelectedClinic")
      ).then((res) => {
        let categoryIds = [];
        const tempData = [...res.data.data]
        tempData.map(product => {
          categoryIds = [...product.categoryId]
          this.quantity = product.quantity
          this.checkItemQuantity(product.quantity, product)
        })
        this.$vs.loading.close();
        this.deliveryFees = [];
        this.getDeliveryCharge();
      });
    },
    submit() {
      this.$vs.loading();
      this.$refs.stripeElement.submit();
      this.isCreditCard = true;
    },
    errorMessage(error) {
      this.$vs.loading.close();
      this.$vs.notify({
        title: "",
        text: error.message,
        color: "danger",
        iconPack: "feather",
        icon: "icon-alert-circle",
      });
    },
    tokenCreated(token) {
      this.token = token;
      this.addNewPaymentMethod({
        token,
        clinicId: sessionStorage.getItem("doctorClinicSelectedClinic"),
        intentId: null,
      })
        .then((res) => {
          const data = res.data.data;
          if (!data.success) {
            this.$vs.notify({
              title: "",
              text: res.data.data.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
            });
          }
          /**
           * Set the newly created card
           * to default payment method
           */
          this.setDefaultPaymentMethod({
            userId: data.userId,
            cardId: data.cardId,
            paymentMethodId: data.paymentMethodId,
            type: "CreditCard",
          }).then((result) => {
            if (result.status === 200) {
              this.$vs.loading.close();
              this.popupActive = false;
              this.getClinicPaymentDetails();
            }
          }).catch((error) => {
            console.log("#ERR: ", error)
          });
        })
        .catch((err) => {
          this.$vs.loading.close();
          console.log(err);
        });
    },
    confirmPayment(response, charge) {
      this.$vs.loading.close();
      this.stripe.handleCardAction(response.payment_intent_client_secret).then(result => {
        if (result.error) {
          this.$vs.notify({
            title: "",
            text: result.error.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          // Show error in payment form
        } else {
          this.sendToServer(charge, result.paymentIntent)
        }
      })
    },
    addSIfNotEndsWithS(obj) {
      const newObj = {};
      for (let key in obj) {
        let newKey = key;
        if (!key.endsWith('s')) {
          newKey += 's';
        }
        newObj[newKey] = obj[key];
      }
      return newObj;
    },
    async saveClinicOpeningHours(clinicId, operations) {
      try {
        await this.$store.dispatch(
          "clinic/updateOpeningHours",
          {
            id: clinicId,
            data: {
              clinicOpeningHours: this.addSIfNotEndsWithS(operations)
            }
          }
        );
      } catch (error) {
        console.log(error.message);
      }
    },
    getMinimumQTy(item) {
      return item.inventory.requiredQuantity;
    },

    async sendToServer(charge, paymentIntent = {}) {
      // Check if smartlook is integrated in the project
      if (window.smartlook) {
        window.smartlook('track', 'storeorders', this.checkDeviceType)
      }
      // Send to charge to your backend server to be processed
      // Documentation here: https://stripe.com/docs/api/charges/create
      this.saveClinicOpeningHours(sessionStorage.getItem("doctorClinicSelectedClinic"), this.operations)
      this.$vs.loading();
      let data = {
        charge: charge,
        shippingDetails: this.shippingDetails,
        paymentDetails: this.paymentDetails,
        operations: this.operations,
        creditCardTransactionFee: this.creditCardTransactionFee,
        deliveryFees: this.deliveryFees,
        ...paymentIntent
      };

      if (this.voucher) {
        data.voucher = {
          voucherId: this.voucher._id,
          totalDiscount: this.voucherPrice,
          discountName: this.voucher.voucher_code,
          discountLabel: this.voucher.discount_title,
          applyAfterGST: this.voucher.applyAfterGST,
        }
      }

      if (this.paymentType) {
        data = { ...data, paymentType: this.paymentType };
      }

      const selectedIds = JSON.parse(localStorage.getItem('selectedIds'))
      // append adminSelectedStaff if super-admin
      if (selectedIds && selectedIds.staffId && this.isLoggedInSuperAdmin) {
        data.adminSelectedStaff = selectedIds.staffId.userId
      }

      // append sendToSage to process sage & send PO to supplier
      if (this.isLoggedInSuperAdmin) {
        data.sendToSage = this.sendToSage
      }

      await this.paymentForCartItems({ ...data })
        .then(async (response) => {
          if (response.requires_action) {
            this.confirmPayment(response, charge);
          } else {
            this.$vs.loading.close();

            let payload = _.compact(this.cartItems.map((item) => {
              const isMatch = this.openingOrderSuppliers.find((i) => i === item.inventory.managedBy)
              if (isMatch && !item.openingOrder) return {
                supplierId: item.inventory.managedBy,
                clinicId: sessionStorage.getItem("doctorClinicSelectedClinic")
              }
              return undefined
            }))

            if (payload.length) {
              await this.createMinimumOrder({
                order: _.uniqBy(payload, 'supplierId')
              })
            }

            this.active = 3;
            this.popupActive = false;
            this.REMOVE_ALL_ITEMS_IN_CART();
            this.$vs.notify({
              title: response.data.title,
              text: response.data.message,
              color: "success",
              iconPack: "feather",
              icon: "icon-alert-circle",
            });

            trackEcommerceEvent(PURCHASE, {
              currency: getCurrency(),
              value: formatPriceValue(this.cartSubTotal),
              transaction_id: response.data.data.newOrder._id,
              coupon: this.couponsList,
              shipping: formatPriceValue(this.deliveryCharge),
              tax: formatPriceValue(this.gstTotal),
              items: this.cartItems.map((item) => {
                return {
                  item_id: item._id,
                  item_name: item.name,
                  price: formatPriceValue(item.price),
                  quantity: item.quantity,
                };
              }),
            });

            if (this.$store.state.AppActiveUser.userType === "superAdmin") {
              this.$router.push({
                name: 'superAdminStoreViewOrder',
                params: {
                  orderId: response.data.data.newOrder._id,
                },
              });
            } else {
              this.$router.push({
                name: this.getRoleRouteName("OrderComplete"),
                query: {
                  orderNumber: response.data.data.newOrder.orderNumber,
                  orderId: response.data.data.newOrder._id,
                },
              });
            }
          }

        })
        .catch((error) => {
          if (["CreditCard", "StoredCard"].includes(this.paymentType)) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: error.data.title,
              text: error.data.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
            });
          } else if (error.data.title === 'Payment Intent Fail') {
            this.$vs.loading.close();
            this.$vs.notify({
              title: error.data.title,
              text: error.data.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
            });
          } else {
            if (error.data.title === "OUTSTANDING_PAYMENT") {
              this.$vs.loading.close();
              this.popupActive2 = true;
              return;
            } else {
              this.$vs.loading.close();
              this.$vs.notify({
                title: error.data.title,
                text: error.data.message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
              });
            }

            if (!data.creditCardTransactionFee) {
              this.REMOVE_ALL_ITEMS_IN_CART();
              setTimeout(() => {
                this.$router.push({ name: this.getRoleRouteName("OrderList") });
              }, 1000);
            }
          }
        });
    },
    checkItemQuantity(quantity, data) {
      const { requiredQuantity, maxQuantity } = data.inventory;
      const itemId = data._id;

      // Check if the quantity is less than the required quantity
      if (this.quantity < requiredQuantity) {
        this.errors.add({
          field: "MinimumQuantityLimit",
          msg: `Minimum order quantity is ${requiredQuantity}`,
          id: itemId,
        });
        this.$vs.notify({
          title: "Failed",
          text: `Minimum quantity for order is ${requiredQuantity}`,
          color: "danger",
        });
      } else {
        this.errors.remove("MinimumQuantityLimit");
      }

      // Check if the quantity is greater than the maximum quantity
      if (maxQuantity !== null && maxQuantity !== 0) {
        if (this.quantity > maxQuantity) {
          this.errors.add({
            field: "MaximumQuantityLimit",
            msg: `Maximum quantity for order is ${maxQuantity}`,
            id: itemId,
          });
          this.$vs.notify({
            title: "Failed",
            text: `Maximum quantity for order is ${maxQuantity}`,
            color: "danger",
          });
        } else {
          this.errors.remove("MaximumQuantityLimit");
        }
      }
    },
    async nextTab() {
      let isValid = await this.$validator.validateAll("basic");
      if (!isValid) {
        this.$vs.notify({
          title: "Error",
          text: "Validation Failed",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }
      this.$refs.checkoutWizard.nextTab();
    },
    savedClinicBankPayment() {
      this.$vs.loading();

      this.paymentForCartItems({
        charge: this.totalPrice,
        shippingDetails: this.shippingDetails,
        paymentType: "SavedClinicBankAccount",
      })
        .then((res) => {
          this.REMOVE_ALL_ITEMS_IN_CART();
          this.active1 = true;
          this.$vs.notify({
            title: res.data.title,
            text: res.data.message,
            color: "success",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          this.$router.push({
            name: this.getRoleRouteName("OrderComplete"),
            query: {
              orderNumber: res.data.data.newOrder.orderNumber,
              orderId: res.data.data.newOrder._id,
            },
          });

          trackEcommerceEvent(PURCHASE, {
            currency: getCurrency(),
            value: formatPriceValue(this.cartSubTotal),
            transaction_id: res.data.data.newOrder._id,
            coupon: this.couponsList,
            shipping: formatPriceValue(this.deliveryCharge),
            tax: formatPriceValue(this.gstTotal),
            items: this.cartItems.map((item) => {
              return {
                item_id: item._id,
                item_name: item.name,
                price: formatPriceValue(item.price),
                quantity: item.quantity,
              };
            }),
          });

          this.$vs.loading.close();
        })
        .catch((err) => {
          this.REMOVE_ALL_ITEMS_IN_CART();
          this.$vs.loading.close();
          const errMessage =
            err.data && err.data.message
              ? err.data.message
              : err.message
                ? err.message
                : "Oops ! something went wrong. Please try later.";
        });
    },
    async getUserDetail() {
      const userDetail = await this.fetchUserDetails();

      if (userDetail.data.data.clinicOpeningHours) {
        const sched = { ...userDetail.data.data.clinicOpeningHours };
        delete sched._id;
        this.operations = {
          monday: sched.mondays,
          tuesday: sched.tuesdays,
          wednesday: sched.wednesdays,
          thursday: sched.thursdays,
          friday: sched.fridays,
          saturday: sched.saturdays,
          sunday: sched.sundays,
        };
      }

      return this.formatUserDetailResponse(userDetail.data.data);
    },
    formatUserDetailResponse(userDetail) {
      return {
        clinicName: userDetail.clinicName,
        address: userDetail.address,
        email: userDetail.email,
      };
    },
    // TAB 1
    removeItemFromCart(item) {
      this.$store.dispatch("eCommerce/toggleItemInCart", item);
      setTimeout(() => {
        this.getCartItems();
      }, 500);
    },
    wishListButtonClicked(item) {
      // Toggle in Wish List
      if (this.isInWishList(item.objectID))
        this.$router.push("/apps/eCommerce/wish-list").catch(() => { });
      else {
        this.toggleItemInWishList(item);
        this.removeItemFromCart(item);
      }
    },
    toggleItemInWishList(item) {
      this.$store.dispatch("eCommerce/toggleItemInWishList", item);
    },
    addQuantity(item) {
      item.quantity = item.quantity + 1
      this.increase(item)
    },
    decreaseQuantity(item) {
      if (item.quantity > 1) {
        item.quantity = item.quantity - 1
        this.increase(item)
      }
    },
    increase(item) {
      item.quantity = Math.trunc(item.quantity);

      return _.debounce(() => {

        // if script product, do not update cart
        if (item.patient !== undefined) {
          return;
        }

        // Disable input first
        this.disableInput = true;

        setTimeout(() => {
          this.disableInput = false
        }, 0)
        // Declare variables
        let cloneCart = _.cloneDeep(this.cartItems);
        let index = _.findIndex(cloneCart, { _id: item._id });

        // Checking if required quantity is met
        if (item.quantity < item.inventory.requiredQuantity) {
          this.errors.add({
            field: "MinimumQuantityLimit",
            msg:
              "Minimum quantity for order is " +
              item.inventory.requiredQuantity,
            id: item._id,
          });
          this.$vs.notify({
            title: "Failed",
            text: "Minimum quantity for order is " + item.inventory.requiredQuantity,
            color: "danger",
          });
          return;
        }

        // Check if its more than max quantity
        if (item.inventory.maxQuantity > 0 && item.quantity > item.inventory.maxQuantity) {
          this.errors.add({
            field: "MaximumQuantityLimit",
            msg:
              "Maximum quantity for order is " + item.inventory.maxQuantity,
            id: item._id,
          });
          this.$vs.notify({
            title: "Failed",
            text: "You've exceeded maximum order amount.",
            color: "danger",
          });
          return;
        }

        // 1. Check if its emtpy
        // 2. Check if its less than 1
        if (!cloneCart[index].quantity || cloneCart[index].quantity < 1) {
          this.cartItems[index].quantity = 1
          item.quantity = 1
          cloneCart[index].quantity = 1
          this.errors.add({
            field: "MinimumQuantityLimit",
            msg: "Minimum quantity for order is 1",
            id: item._id,
          });
        }

        // Check if its more than 999
        if (cloneCart[index].quantity > 999) {
          this.cartItems[index].quantity = 999
          cloneCart[index].quantity = 999
          this.errors.add({
            field: "MaximumQuantityLimit",
            msg: "Maximum quantity for order is 999",
            id: item._id,
          });
        }

        this.errors.removeById(item._id);

        // convert to original
        if (item.hasOwnProperty('superAdminPrice')) item.adjustedPrice = item.superAdminPrice * 100
        if (item.hasOwnProperty('superAdminDiscountPrice') && this.isDiscounted(item)) item.adjustedDiscountPrice = item.superAdminDiscountPrice * 100

        // remove keys superAdminDiscountPrice if not discounted
        if (item.hasOwnProperty('superAdminDiscountPrice') && !this.isDiscounted(item)) {
          delete item.superAdminDiscountPrice
          delete item.adjustedDiscountPrice
          delete item.superAdminDiscountPriceFrom
        }

        this.$store.dispatch("eCommerce/updateItemQuantity", {
          item: item,
        });
        setTimeout(() => {
          this.getCartItems();
        }, 500)
      }, 500)()

    },
    decrease(item) {
      const _id = item._id;
      if (item.patient === undefined) {
        let cloneCart = _.cloneDeep(this.cartItems);
        let index = _.findIndex(cloneCart, { _id });

        // cloneCart[index].quantity = cloneCart[index].quantity - 1;

        let item = cloneCart[index];

        if (item.quantity < item.inventory.requiredQuantity) {
          this.errors.add({
            field: "MinimumQuantityLimit",
            msg:
              "Minimum quantity for order is " +
              item.inventory.requiredQuantity,
            id: item._id,
          });
        } else {
          this.errors.removeById(item._id);
          this.$store.dispatch("eCommerce/updateItemQuantity", {
            item: item,
          });
        }
        if (
          item.inventory.maxQuantity !== null &&
          item.inventory.maxQuantity !== 0
        ) {
          if (item.quantity > item.inventory.maxQuantity) {
            this.errors.add({
              field: "MaximumQuantityLimit",
              msg:
                "Maximum quantity for order is " + item.inventory.maxQuantity,
              id: item._id,
            });
          }
        }
      }
    },
    async reOrderItems(orderId) {
      try {
        this.$vs.loading();
        const res = await this.orderAgain({ orderId });
        const { data: { data } } = res
        if (res.status === 200) {
          // Clear orderId when reorder succeeds
          localStorage.removeItem("orderId")
          setTimeout(() => {
            this.getCartItems();
          }, 500);
          // check
          data.forEach(item => {
            this.quantity = item.quantity
            this.checkItemQuantity(item.quantity, item)
          })
          this.$vs.loading.close();
        }
      } catch (error) {
        this.$vs.loading.close();
      }
    },
    async fetch_item_details(productId, quantity) {
      let localErrors = [];
      try {
        this.$vs.loading();
        const response = await this.fetchProductDetail(productId);
        const item = response.data.data.product;
        if (quantity < item.inventory.requiredQuantity) {
          localErrors.push({
            field: "MinimumQuantityLimit",
            msg:
              "Minimum quantity for order is " +
              item.inventory.requiredQuantity,
            id: item._id,
          });
          this.errors.add({
            field: "MinimumQuantityLimit",
            msg:
              "Minimum quantity for order is " +
              item.inventory.requiredQuantity,
            id: item._id,
          });
          this.$vs.notify({
            title: "Failed",
            text: `Minimum quantity for order is ${item.inventory.requiredQuantity}`,
            color: "danger",
          });
        }

        if (
          item.inventory.maxQuantity !== null &&
          item.inventory.maxQuantity !== 0
        ) {
          if (quantity > item.inventory.maxQuantity) {
            localErrors.push({
              field: "MaximumQuantityLimit",
              msg:
                "Maximum quantity for order is " + item.inventory.maxQuantity,
              id: item._id,
            });
            this.errors.add({
              field: "MaximumQuantityLimit",
              msg:
                "Maximum quantity for order is " + item.inventory.maxQuantity,
              id: item._id,
            });
            this.$vs.notify({
              title: "Failed",
              text: `Maximum quantity for order is ${item.inventory.maxQuantity}`,
              color: "danger",
            });
          }
        }

        this.$vs.loading.close();
        return localErrors

      } catch (error) {
        this.$vs.loading.close();
      }
    }
  },
  components: {
    BankSetup,
    ClinicBankDetailSetup,
    CheckoutListView,
    FormWizard,
    TabContent,
    StripeElements,
    Tabs,
    PlusIcon,
    XIcon,
    SwapClinic,
    CheckoutVoucher
  },
  beforeMount: async function () {
    if (
      localStorage.getItem(`${process.env.VUE_APP_PROCESS_TOKEN}OrganizationId`)
    ) {
      this.hasOrganization = true;
    }
    this.limitationsList = this.$store.state.currentStoreLimitation;
    this.role = localStorage.getItem(
      `${process.env.VUE_APP_PROCESS_TOKEN}Role`
    );
  },
  mounted() {
    loadStripeSdk(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY, 'v3', () => {
      const options = {
        locale: "en",
      };
      this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY, options);
    });
  }
};
</script>

<style lang="scss" scoped>
.voucher-card {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .25rem;
}

.inputNumber {
  margin: 5px;
  background: #f5f5f5;
  padding: 5px 15px;
  border-radius: 5px;
}

#ecommerce-checkout-demo {
  .increment-buttons {
    height: 42px;
    background: #3cc9b230;
    width: 100px;
  }

  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
  }

  .item-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
  }

  .vue-form-wizard {
    padding-bottom: 0;

    ::v-deep .wizard-header {
      padding: 0;
    }

    ::v-deep .wizard-tab-content {
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 0;

      .wizard-tab-container {
        margin-bottom: 0 !important;
      }
    }
  }

  .add-new-address-form {
    ::v-deep .con-text-validation .span-text-validation {
      display: none;
    }
  }


  @media (min-width: 1024px) {
    .increment-buttons {
      border-radius: 5px;
    }

    .remove-button {
      border-radius: 5px;
    }
  }
}

.learnMorePopup .vs-popup {
  width: 50%;
}

.savings {
  background: #EBF7F4;
}

.delivery-text {
  color: rgba(38, 41, 40, 0.65);
}

.price-text {
  color: rgba(38, 41, 40, 1);
}

.circle {
  top: 10px;
  right: 10px;
}
</style>
