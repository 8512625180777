<template>
  <SaveCategory
    @saveDetail="saveCategory"
    @cancelCreate="cancelCreate"
    :categoryDetail="categoryDetail"
    :update="false"
  />
</template>

<script>
import SaveCategory from "../../../components/reporting-category/add.vue";

export default {
  name: "Add Category",
  components: {
    SaveCategory,
  },
  data: () => ({
    categoryDetail: {
      name: "",
      slug: "",
      parentCategory: "",
      productDescription: "",
      matchingCondition: "",
      conditions: [],
      image: "",
      categoryVisibility: "public",
      desktopImage: '',
      mobileImage: ''
    },
  }),
  methods: {
    async saveCategory(data) {
      try {
        await this.$store.dispatch("reportingCategory/addCategory", data);
        this.$vs.notify({
          title: "Reporting Category Add",
          text: " Reporting Category Added successfully",
          color: "success",
        });
        this.$vs.loading.close();
        this.$router.push({ name: "CategoryManagement" });
      } catch (err) {
        console.error("ERR: ", err);
        this.$vs.notify({
          title: "Reporting Category Add",
          text: err.data.message || "Failed",
          color: "danger",
        })
      }

    },
    cancelCreate() {
      this.$router.push({ name: "CategoryManagement" });
    },
  },
};
</script>
