<template>
  <div>
    <!-- <vs-card>
      <p class="text-lg font-normal hidden sm:block">
    Drugbook Entries Outstanding: X. Please complete the Drugbook section
    for all treatments performed in the Mobile App.
    </p>
    <p class="text-lg font-normal block sm:hidden">
    Drugbook Entries Outstanding: X. Complete in Mobile App.
    </p>

    </vs-card> -->
    <iframe id="my_iframe" style="display:none;"></iframe>
    <IncorrectBalanceBanner v-if="hasDrugbookIncorrectBalance" :url="drugbookIncorrectBalanceBannerURL" />
    <vs-card v-if="toggleScreenSize">
      <div class="justify-between flex flex-no-wrap">
        <h3 class="mt-3 mb-10 pl-2">Drugbook</h3>
        <div v-if="hasClinicNotSetup" class="pt-2">
          <vs-tooltip v-if="!token" text="Complete Set Up">
            <vs-button @click="redirectToSetup" type="flat">
              Complete onboarding
            </vs-button>
          </vs-tooltip>
        </div>
      </div>
      <div class="md:justify-between md:flex md:items-end">
        <progress-bar :month="selectedMonth.toString()" :year="selectedYear.toString()" :clinicId="selectedClinic"/>
        <div class="mb-4 ml-2" >
          <div class="hidden md:flex gap-3 justify-end">
            <vs-button class="h-12 text-center py-2 text-sm" @click="addCustomRecordPopup = true"
              :disabled="!filterCheck()">
              Add Treatment
            </vs-button>
            <vs-button class="h-12 text-center py-2 text-sm" @click="discardRecordPopup = true"
              :disabled="!filterCheck()">
              Disposal Record
            </vs-button>
            <vs-button class="h-12 text-center py-2 text-sm" @click="addRecordPopUp = true"
              :disabled="!filterCheck()">
              Add Stock
            </vs-button>
            <!-- <vs-dropdown vs-trigger-click>
              <vs-button class="h-12 text-center py-2 text-sm">
                Export
              </vs-button>
              <vs-dropdown-menu class="w-48">
                <div class="p-2 hover:bg-primary cursor-pointer hover:text-white" @click="genratePdfReport">Export PDF</div>
                <div class="p-2 hover:bg-primary cursor-pointer hover:text-white" @click="generateCSVReport">Export CSV</div>
              </vs-dropdown-menu>
              </vs-dropdown>
              <vs-button class="h-12 text-center py-2 text-sm" @click="genratePdfReport">
                Export PDF
              </vs-button> -->
              <ExportDrugbook :clinicsList="clinicsList" :drugItems="drugItemsWithCustomProducts" :organizationId="organizationId" :disabled="!filterCheck()"/>
          </div>
        </div>
      </div>
      <div class="flex justify-between items-center mb-4">
        <div class="flex w-full md:flex-no-wrap flex-wrap gap-3" v-if="filterCheck()">
          <div class="flex justify-between items-center w-full md:w-2/5 pt-2 filterContainer customBorder relative">
            <vs-select ref="yearSelect" class="customSelect m-0" width="100%" label="Year" name="year"
              v-model="selectedYear" autocomplete icon="" :disabled="filterDisabled.year">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in customYears" />
            </vs-select>
            <div class="cursor-pointer changeBtn absolute btnyear" @click="changeYear">
              Change
            </div>
            <!-- <div>
              <div>Month</div>
              <h4 class="truncate">August 2022</h4>
            </div>
            <div>
              <div>Change</div>
            </div> -->
          </div>
          <div class="flex justify-between items-center w-full md:w-2/5 pt-2 filterContainer customBorder relative">
            <vs-select ref="monthSelect" class="customSelect m-0" width="100%" label="Month" name="month"
              v-model="selectedMonth" autocomplete icon="" :disabled="filterDisabled.month">
              <vs-select-item :key="index" :value="item.value" :text="item.text"
                v-for="(item, index) in customMonths" />
            </vs-select>
            <div class="cursor-pointer changeBtn absolute btnmonth" @click="changeMonth">
              Change
            </div>
            <!-- <div>
              <div>Month</div>
              <h4 class="truncate">August 2022</h4>
            </div>
            <div>
              <div>Change</div>
            </div> -->
          </div>
          <div class="flex justify-between items-center w-full md:w-2/5 pt-2 filterContainer customBorder relative">
            <vs-select ref="clinicSelect" class="customSelect" width="100%" label="Clinic" name="clinic"
              v-model="selectedClinic" autocomplete icon="" :disabled="filterDisabled.clinic">
              <vs-select-item :key="index" :value="item.clinicId" :text="item.name"
                v-for="(item, index) in clinicsList" />
            </vs-select>
            <div class="cursor-pointer changeBtn absolute btnclinic" @click="changeClinic">
              Change
            </div>
            <!-- <div>
              <div>Clinic</div>
              <h4 class="truncate">Carrington St, Sydney Be...</h4>
            </div>
            <div>
              <div>Change</div>
            </div> -->
          </div>
          <div class="flex justify-between items-center w-full md:w-2/5 pt-2 filterContainer customBorder relative">
            <vs-select ref="drugSelect" class="customSelect" width="100%" label="Drug" name="drug"
              v-model="selectedDrug" autocomplete icon="" :disabled="filterDisabled.drug">
              <div :key="index" v-for="(item, index) in filteredProducts">
                <vs-select-group :title="item.title" v-if="item.group">
                  <vs-select-item :key="index" :value="item.value" :text="item.text"
                    v-for="(item, index) in item.group" />
                </vs-select-group>
              </div>
            </vs-select>
            <div class="cursor-pointer changeBtn absolute btndrug" @click="changeDrug">
              Change
            </div>
            <!-- <div>
              <div>Drug</div>
              <h4 class="truncate">Juvederm Ultra XC Plus</h4>
            </div>
            <div>
              <div>Change</div>
            </div> -->
          </div>
        </div>
        <div class="flex w-full md:w-7/12 md:flex-no-wrap flex-wrap gap-3" v-else>
          <div class="flex justify-between items-center w-full md:w-1/3 p-2">
            <vs-select width="100%" placeholder="Select Year" name="year" v-model="selectedYear" autocomplete>
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in customYears" />
            </vs-select>
          </div>
          <div class="flex justify-between items-center w-full md:w-1/3 p-2">
            <vs-select width="100%" placeholder="Select Month" name="month" v-model="selectedMonth" autocomplete>
              <vs-select-item :key="index" :value="item.value" :text="item.text"
                v-for="(item, index) in customMonths" />
            </vs-select>
          </div>
          <div class="flex justify-between items-center w-full md:w-1/3 p-2">
            <vs-select width="100%" placeholder="Select Drug" name="drug" v-model="selectedDrug" autocomplete>
              <div :key="index" v-for="(item, index) in filteredProducts">
                <vs-select-group :title="item.title" v-if="item.group">
                  <vs-select-item :key="index" :value="item.value" :text="item.text"
                    v-for="(item, index) in item.group" />
                </vs-select-group>
              </div>
            </vs-select>
          </div>
          <div class="flex justify-between items-center w-full md:w-1/3 p-2">
            <vs-select v-if="clinicsList" width="100%" placeholder="Select Clinic" name="clinic"
              v-model="selectedClinic" autocomplete>
              <vs-select-item :key="index" :value="item.clinicId" :text="item.name"
                v-for="(item, index) in clinicsList" />
            </vs-select>
          </div>
        </div>
      </div>
      <template v-if="filterCheck()">
        <div class="w-full flex my-5 gap-3 md:flex-no-wrap flex-wrap">
          <div class="flex w-full md:w-1/2 gap-3">
            <div class="w-1/2">
              <div class="w-full flex items-center py-5 totalContainer">
                <div class="w-full text-center">
                  <span>Total Received</span>
                  <h4>
                    {{totalSummaryFormatted(summary.received)}}
                  </h4>
                </div>
              </div>
            </div>
            <div class="w-1/2">
              <div class="w-full flex items-center py-5 totalContainer">
                <div class="w-full text-center">
                  <span>Total Administered</span>
                  <h4>
                    {{totalSummaryFormatted(summary.administered)}}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div class="flex w-full md:w-1/2 gap-3">
            <div class="w-1/2">
              <div class="w-full flex items-center py-5 totalContainer">
                <div class="w-full text-center">
                  <span>Total Discarded</span>
                  <h4>
                    {{totalSummaryFormatted(summary.discarded)}}
                  </h4>
                </div>
              </div>
            </div>
            <div class="w-1/2">
              <div class="w-full flex items-center py-5 totalContainer">
                <div class="w-full text-center">
                  <span>Total Current Balance</span>
                  <h4>
                    {{totalSummaryFormatted(summary.current)}}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="data-list-list-view" class="data-list-container" v-if="filterCheck() && this.clinicData">
          <vs-table class="hidden md:block" ref="table" :sst="true" :total="totalDocs" :data="drugEntries"
            :max-items="dataTableParams.limit" :noDataText="noDataText" @sort="handleSort" @change-page="handleChangePage">
            <!-- :sst="true"
            :total="totalDocs"
            :data="users"
            @search="search"
            @change-page="handleChangePage"
            @sort="handleSort"
            :max-items="dataTableParams.limit"
            search
            :noDataText="noDataText" -->
            <!-- <div slot="header" class="flex flex-grow justify-between">
              <div class="flex">
                <vs-select placeholder="All Drugs" autocomplete class="mr-6">
                  <vs-select-item
                    :key="index"
                    :value="item.clinicId"
                    :text="item.name"
                    v-for="(item, index) in clinics"
                  /> </vs-select
                ><vs-select placeholder="All Months" autocomplete class="mr-6">
                  <vs-select-item
                    :key="index"
                    :value="item.clinicId"
                    :text="item.name"
                    v-for="(item, index) in clinics"
                  />
                </vs-select>
                <vs-select placeholder="All Clinics" autocomplete class="mr-6">
                  <vs-select-item
                    :key="index"
                    :value="item.clinicId"
                    :text="item.name"
                    v-for="(item, index) in clinics"
                  />
                </vs-select>
                <vs-select
                  v-model="dataTableParams.clinicId"
                  placeholder="All Clinic"
                  autocomplete
                  class="mr-6"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.clinicId"
                    :text="item.name"
                    v-for="(item, index) in clinics"
                  />
                </vs-select>
              </div>
              <div>
                <vs-button class="m-1" @click="addRecordPopUp = true">
                  Add Record</vs-button
                >
                <vs-button class="btnx" type="filled">Dropdown</vs-button>
                <vs-dropdown>
                  <vs-button class="btn-drop" type="filled"
                    >Add Record <vs-icon class="" icon="expand_more"></vs-icon>
                  </vs-button>

                  <vs-dropdown-menu class="dropdownMenu">
                    <vs-dropdown-item
                      class="w-auto py-2"
                      @click="addRecordPopUp = true"
                    >
                      <div style="display: flex; justify-content: space-between">
                        <span>Add Stock Received</span>
                        <vs-icon size="small" icon="event_available"></vs-icon>
                      </div>
                    </vs-dropdown-item>
                    <vs-button type="link" class="m-1" @click="addRecordPopUp = true">
                      Add Record</vs-button
                    >
                    <vs-button color="primary" type="flat">Primary</vs-button>
                    <vs-dropdown-item
                      class="w-auto py-2"
                      @click="discardRecordPopup = true"
                    >
                      <div style="display: flex; justify-content: space-between">
                        <span>Add a Disposal Record</span>
                        <vs-icon size="small" icon="delete"></vs-icon>
                        <DisposalRecord :yaknunTa="discardRecordPopup" />
                      </div>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
                <vs-button class="m-1" @click="updatePopUp = true">
                  Edit</vs-button
                >
              </div>
            </div> -->

            <template slot="thead">
              <vs-th></vs-th>
              <vs-th sort-key="entryDate">Date</vs-th>
              <vs-th sort-key="entryDate">Time</vs-th>
              <vs-th>Clinic</vs-th>
              <vs-th>Drug</vs-th>
              <vs-th sort-key="area">Area</vs-th>
              <vs-th sort-key="patientName">Patient</vs-th>
              <vs-th sort-key="stocked">Recieved</vs-th>
              <vs-th sort-key="used">Administered</vs-th>
              <vs-th sort-key="discarded">Discarded</vs-th>
              <vs-th sort-key="balance">Balance</vs-th>
              <vs-th sort-key="batchNumber">Batch Number</vs-th>
              <vs-th>Received, Administered, Discarded By</vs-th>
              <vs-th v-if="!isNZRegion">Prescriber</vs-th>
              <vs-th sort-key="notes">Notes</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr">
                  <p :class="(tr.overriden || tr.type === 'adjustment' || (tr.performedBy._id !== userId && !isOrgOwner)) ? 'opacity-50 text-grey m-1' : 'm-1 hover:text-opacity-75 text-primary cursor-pointer'"
                    @click="editRecord(tr)">
                    Edit</p>
                </vs-td>
                <vs-td :data="tr.entryDate" :class="tr.overriden && ' line-through opacity-50'">{{
                    moment(tr.entryDate).format("DD/MM/YYYY")
                }}</vs-td>
                <vs-td :data="tr.entryDate" :class="tr.overriden && ' line-through opacity-50'">{{
                    moment(tr.entryDate).format("hh:mm A")
                }}</vs-td>
                <vs-td :data="tr.clinicId" :class="tr.overriden && ' line-through opacity-50'">{{ clinicData.name
                }}</vs-td>
                <vs-td :data="tr.productInfo" :class="tr.overriden && ' line-through opacity-50'">{{
                tr.productInfo.name ? tr.productInfo.name : tr.customProduct.name ? tr.customProduct.name : ''
                }}</vs-td>
                <vs-td :data="tr.area" :class="tr.overriden && ' line-through opacity-50'">
                  {{ tr.area }}</vs-td>
                <vs-td :data="tr.patientName" :class="tr.overriden && ' line-through opacity-50'">{{ tr.patientName | capitalize
                }}</vs-td>
                <vs-td :data="tr.amount" :class="tr.overriden && ' line-through opacity-50'">{{ getAmount("stocked", tr)
                }}</vs-td>
                <vs-td :data="tr.amount" :class="tr.overriden && ' line-through opacity-50'">{{ getAmount("used", tr)
                }}</vs-td>
                <vs-td :data="tr.amount" :class="tr.overriden && ' line-through opacity-50'">{{ getAmount("discarded",
                    tr)
                }}</vs-td>
                <vs-td :data="tr.balance" :class="tr.overriden && ' line-through opacity-50'">{{
                    parseFloat(tr.balance).toFixed(2)
                }}</vs-td>
                <vs-td :data="tr.batchNumber" :class="tr.overriden && ' line-through opacity-50'">{{ tr.batchNumber
                }}</vs-td>
                <vs-td :data="tr.performedBy.name" :class="tr.overriden && ' line-through opacity-50'">{{
                    tr.performedBy.name
                }}</vs-td>
                <vs-td :data="tr.prescriber.name" :class="tr.overriden && ' line-through opacity-50'">{{
                    tr.prescriber.name ? tr.prescriber.name : tr.customPrescriber.name ? tr.customPrescriber.name : 'Fresh Clinics'
                }}</vs-td>
                <vs-td :data="tr.discardReason" :class="tr.overriden && ' line-through opacity-50'">
                  {{ tr.notes ? tr.notes : tr.discardReason }}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <template v-if="toggleScreenSize && isMobileView">
            <div class="block md:hidden gap-3 w-full justify-end items-center">
              <vs-button class="w-full h-12 text-center py-2 my-1 text-sm" @click="addCustomRecordPopup = true"
                :disabled="!filterCheck()">
                Add Treatment
              </vs-button>
              <vs-button class="w-full h-12 text-center py-2 my-1 text-sm" @click="discardRecordPopup = true"
                :disabled="!filterCheck()">
                Disposal Record
              </vs-button>
              <vs-button class="w-full h-12 text-center py-2 my-1 text-sm" @click="addRecordPopUp = true"
                :disabled="!filterCheck()">
                Add Stock
              </vs-button>
              <ExportDrugbook :clinicsList="clinicsList" :drugItems="drugItemsWithCustomProducts" :organizationId="organizationId" :disabled="!filterCheck()"/>

            </div>
            <div v-if="isMobileView">
              <vs-collapse type="default">
                <vs-collapse-item v-for="item in drugEntries" :key="item._id" :not-arrow="true" ref="vsCollapseItem">
                  <div slot="header">
                    <div class="flex justify-between">
                      <div class="">
                        <div>
                          <span :class="`basic-text ${item.overriden ? 'line-through' : null
                          }`">{{item.productInfo ? item.productInfo.name : item.customProduct.name ? tr.customProduct.name:''}}</span>
                        </div>
                        <div>
                          <span :class="`basic-text ${item.overriden ? 'line-through' : null
                          }`">
                            {{ moment(item.entryDate).format("hh:mm A") }}
                          </span>
                        </div>
                      </div>
                      <div class="">
                        <!-- <vs-chip v-if="item.type === 'discarded'">
                        {{`-${item.amount}${item.productInfo.dosage}`}}
                      </vs-chip> -->
                        <vs-chip v-if="item.type === 'discarded'" transparent :class="`basic-text text-center ${item.overriden ? 'line-through' : null
                        }`" color="rgba(253, 198, 198, 1)">
                          {{
                              `-${parseFloat(item.amount).toFixed(2)} ${item.productInfo.dosage
                              }`
                          }}
                        </vs-chip>
                        <vs-chip v-if="item.type === 'stocked'" transparent :class="`basic-text text-center ${item.overriden ? 'line-through' : null
                        }`" color="rgba(132, 205, 184, 0.2)">
                          {{
                              `+${parseFloat(item.amount).toFixed(2)} ${item.productInfo.dosage
                              }`
                          }}
                        </vs-chip>
                        <span v-if="item.type === 'used'" class="text-center">
                          {{
                              `-${parseFloat(item.amount).toFixed(2)} ${item.productInfo.dosage
                              }`
                          }}
                        </span>
                        <!-- <vs-chip v-if="item.type === 'used'">
                        {{`-${item.amount}${item.productInfo.dosage}`}}
                      </vs-chip> -->
                        <span :class="`basic-text ${item.overriden ? 'line-through' : null
                        }`">
                          Balance: {{ parseFloat(item.balance).toFixed(2) }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div v-if="!item.overriden">
                      <vs-chip v-if="item.type === 'discarded'" class="basic-text" transparent
                        color="rgba(253, 198, 198, 1)">
                        Discarded
                      </vs-chip>
                      <vs-chip v-if="item.type === 'stocked'">
                        Recieved
                      </vs-chip>
                      <vs-chip v-if="item.type === 'used'">
                        Administered
                      </vs-chip>
                    </div>
                    <vs-chip v-if="item.overriden"> Written in error </vs-chip>
                    <br /><br />
                    <div v-if="item.type === 'stocked'" class="w-full text-gray-500 text-sm py-1">
                      Received by
                    </div>
                    <div v-if="item.type === 'discarded'" class="w-full text-gray-500 text-sm py-1">
                      Discarded by
                    </div>
                    <div v-if="item.type === 'used'" class="w-full text-gray-500 text-sm py-1">
                      Administered by
                    </div>
                    <div :class="`w-full py-1 basic-text ${item.overriden ? 'line-through' : null
                    }`">
                      {{ item.performedBy.name }}
                    </div>

                    <div class="w-full text-gray-500 text-sm py-1">Clinic</div>
                    <div :class="`w-full py-1 basic-text ${item.overriden ? 'line-through' : null
                    }`">
                      {{ clinicData.name }}
                    </div>

                    <div v-if="item.type === 'used'" class="w-full text-gray-500 text-sm py-1">
                      Patient
                    </div>
                    <div v-if="item.type === 'used'" :class="`w-full py-1 basic-text ${item.overriden ? 'line-through' : null
                    }`">
                      {{ item.patientName || "--" | capitalize }}
                    </div>

                    <div v-if="item.type === 'used'" class="w-full text-gray-500 text-sm py-1">
                      Area
                    </div>
                    <div v-if="item.type === 'used'" :class="`w-full py-1 basic-text ${item.overriden ? 'line-through' : null
                    }`">
                      {{ item.treatmentArea || "--" }}
                    </div>

                    <div class="w-full text-gray-500 text-sm py-1">
                      Batch number
                    </div>
                    <div :class="`w-full py-1 basic-text ${item.overriden ? 'line-through' : null
                    }`">
                      {{ item.batchNumber || "--" }}
                    </div>

                    <div class="w-full text-gray-500 text-sm py-1">
                      Batch Expiry
                    </div>
                    <div :class="`w-full py-1 basic-text ${item.overriden ? 'line-through' : null
                    }`">
                      {{ moment(item.expiryDate).format("DD/MM/YYYY") || "--" }}
                    </div>

                    <div class="w-full text-gray-500 text-sm py-1">
                      Prescribed By
                    </div>
                    <div :class="`w-full py-1 basic-text ${item.overriden ? 'line-through' : null
                    }`">
                      {{ item.prescriber.name ? item.prescriber.name :  item.customPrescriber.name ? item.customPrescriber.name : 'Fresh Clinics'}}
                    </div>

                    <div v-if="['discarded', 'used'].includes(item.type)" class="w-full text-gray-500 text-sm">
                      Notes
                    </div>
                    <div v-if="['discarded', 'used'].includes(item.type)" :class="`w-full py-1 basic-text ${item.overriden ? 'line-through' : null
                    }`">
                      {{ item.notes ? item.notes : item.discardReason || "--" }}
                    </div>

                    <!-- <div class=" w-full text-gray-500 text-sm py-1">Administered, Discarded</div>
                  <div class=" w-full text-base py-1">1.5mL, 0.5mL</div> -->

                    <!-- <div class=" w-full text-gray-500 text-sm">Authorising Dr.</div>
                  <div class=" w-full text-base">John Holbrook</div> -->
                  </div>
                </vs-collapse-item>
              </vs-collapse>
            </div>
          </template>
          <div class="p-3 flex justify-end">
            <div class="m-2">
              <pagination
                  :hasPrev="hasPrev"
                  :hasNext="hasNext"
                  @handleOnChangePage="handleChangePage"
                />
              <!-- <span class="mr-2">
                {{
                    dataTableParams.page * dataTableParams.limit -
                    (dataTableParams.limit - 1)
                }}
                -
                {{
                    totalDocs - dataTableParams.page * dataTableParams.limit > 0
                      ? dataTableParams.page * dataTableParams.limit
                      : totalDocs
                }}
                of {{ totalDocs }}
              </span> -->
              <!-- <div class="pagination-div hidden lg:block" v-if="serverResponded"> -->
              <!-- <div class="pagination-div hidden lg:block">
                <paginate :page-count="totalPage" :click-handler="handleChangePage" class="pagination" :page-range="9"
                  :prevText="'<'" :nextText="'>'"></paginate>
              </div> -->
              <!-- <div class="pagination-div lg:hidden mt-3" v-if="serverResponded"> -->
              <!-- <div class="pagination-div lg:hidden">
                <paginate :page-count="totalPage" :click-handler="handleChangePage" class="pagination" :page-range="9"
                  :prevText="'<'" :nextText="'>'"></paginate>
              </div> -->
            </div>
          </div>
          <!-- <vs-popup title="Stock Received" :active.sync="addRecordPopUp">
            <AddRecord :closeAddRecord="closeAddRecord" :selectedClinic="selectedClinic"
              @fetchDrugEntries="getDrugEntries" :selectedDrug="selectedDrug" />
          </vs-popup> -->
          <!-- <vs-popup :active.sync="discardRecordPopup" title="Disposal Record">
            <DisposalRecord :clinic="clinicData" :drugData="selectedDrug" @cancelForm="closeDisposalRecord"
              @fetchDrugEntries="getDrugEntries" />
          </vs-popup> -->
        </div>
      </template>
      <div class="no-filter-view" v-else>
        <p>
          To view your drugbook, or add stock/disposal records please select the
          desired
          <strong>Month, Year, Drug, and Clinic</strong>
        </p>
      </div>
    </vs-card>
    <div v-show="!isMobileView && isMobileView !== null">
      <vs-popup class="popUpCustom" :active.sync="discardRecordPopup" title="Disposal Record" :key="1"
        v-if="discardRecordPopup && !isMobileView && isMobileView !== null">
        <DisposalRecord :show="discardRecordPopup" :clinic="clinicData" :drugData="selectedDrug" @changeSelectedDrug="selectedDrug = $event"
          @cancelForm="closeDisposalRecord" @fetchDrugEntries="getDrugEntries" @fetchCustomProductss="getCustomProducts" />
      </vs-popup>
    </div>
    <vs-card v-show="isMobileView">
      <DisposalRecord :show="discardRecordPopup" v-if="discardRecordPopup" :clinic="clinicData" :drugData="selectedDrug"
        @cancelForm="closeDisposalRecord" :labelVisibility="true" @fetchDrugEntries="getDrugEntries"
        @fetchCustomProductss="getCustomProducts" :key="2" @changeSelectedDrug="selectedDrug = $event" />
    </vs-card>
    <div v-show="!isMobileView && isMobileView !== null">
      <vs-popup class="popUpCustom" title="Stock Received" :active.sync="addRecordPopUp" :key="3"
        v-if="addRecordPopUp && !isMobileView && isMobileView !== null">
        <AddRecord :show="addRecordPopUp" :closeAddRecord="closeAddRecord" :selectedClinic="selectedClinic"
          @fetchDrugEntries="getDrugEntries" :selectedDrug="selectedDrug" @fetchCustomProductss="getCustomProducts"
          @changeSelectedDrug="selectedDrug = $event" />
      </vs-popup>
    </div>
    <vs-card v-show="isMobileView">
      <AddRecord :show="addRecordPopUp" v-if="addRecordPopUp" :isMobile="isMobileView" :clinic="clinicData"
        :closeAddRecord="closeAddRecord" :selectedClinic="selectedClinic" :key="4" @fetchDrugEntries="getDrugEntries"
        :selectedDrug="selectedDrug" @fetchCustomProductss="getCustomProducts" @changeSelectedDrug="selectedDrug = $event" />
    </vs-card>
    <!-- <vs-popup id="explainer" title="" :active.sync="explainerPopUp" >
      <ExplainerPopUp v-if="explainerPopUp" />
    </vs-popup> -->
    <div v-show="!isMobileView && isMobileView !== null">
      <vs-popup  class="popUpCustom" :active.sync="updatePopUp" title="Edit Record" :key="1"
        v-if="updatePopUp && !isMobileView && isMobileView !== null">
        <UpdateDrugbookEntry v-if="updatePopUp" :customProducts="customProducts" :clinic="clinicData" :drugData="selectedDrug" :record="selectedRecord"
          @cancelForm="closeUpdateRecord" @fetchDrugEntries="getDrugEntries" @fetchCustomProductss="getCustomProducts"
          :labelVisibility="true" />
      </vs-popup>
    </div>
    <!-- TODO: fetch custom products and pass it to this component -->
    <vs-card v-show="isMobileView">
      <UpdateDrugbookEntry v-if="updatePopUp" :customProducts="customProducts" :clinic="clinicData" :drugData="selectedDrug" :record="selectedRecord"
        @cancelForm="closeUpdateRecord" :labelVisibility="true" @fetchDrugEntries="getDrugEntries" :key="2"
        @fetchCustomProductss="getCustomProducts" @changeSelectedDrug="selectedDrug = $event" />
    </vs-card>
    <div v-show="!isMobileView && isMobileView !== null">
      <vs-popup class="popUpCustom" :active.sync="addCustomRecordPopup" title="" :key="9"
        v-if="addCustomRecordPopup && !isMobileView && isMobileView !== null">
        <AddCustomRecord v-if="addCustomRecordPopup" :clinic="clinicData" :drugData="selectedDrug" :record="selectedRecord"
          :selectedDrug="selectedDrug" :cancelForm="closeAddCustomRecord" @fetchDrugEntries="getDrugEntries"
          @fetchCustomProductss="getCustomProducts" @changeSelectedDrug="selectedDrug = $event" />
      </vs-popup>
    </div>
    <vs-card v-show="isMobileView">
      <AddCustomRecord :show="addCustomRecordPopup" v-if="addCustomRecordPopup" :isMobile="isMobileView" :clinic="clinicData"
        :cancelForm="closeAddCustomRecord" :selectedClinic="selectedClinic" :key="4" @fetchDrugEntries="getDrugEntries"
        :selectedDrug="selectedDrug" @fetchCustomProductss="getCustomProducts" @changeSelectedDrug="selectedDrug = $event" />
    </vs-card>
  </div>
</template>

<script>
import Vue from "vue";
import DisposalRecord from "../../components/org-owner/drugbook/disposalRecord.vue";
import AddRecord from "../../components/org-owner/drugbook/addRecord.vue";
import AddCustomRecord from "../../components/org-owner/drugbook/addCustomRecord.vue";
import _ from "lodash";
import Datepicker from "vuejs-datepicker";
import { Validator } from "vee-validate";
import ExplainerPopUp from "../../components/org-owner/drugbook/ExplainerPopUp.vue";
import UpdateDrugbookEntry from "../../components/org-owner/drugbook/UpdateDrugbookEntry.vue";
import { mapActions, mapMutations } from "vuex";
import jwt_decode from 'jwt-decode';
import Pagination from "../../general-component/pagination.vue";
import moment from "moment";
import ProgressBar from '@/views/components/drugbook/ProgressBar.vue';
import ExportDrugbook from './ExportDrugbookModal.vue';
import IncorrectBalanceBanner from "../../components/drugbook/incorrectBalanceBanner.vue";

const dict = {
  custom: {
    dateAdded: {
      required: "Please enter the date added",
    },
  },
};

Validator.localize("en", dict);
Vue.prototype.moment = moment;
export default {
  inject: ["getRoleRouteName"],
  components: {
    Datepicker,
    AddRecord,
    DisposalRecord,
    UpdateDrugbookEntry,
    ExplainerPopUp,
    AddCustomRecord,
    ProgressBar,
    ExportDrugbook,
    Pagination,
    IncorrectBalanceBanner,
  },
  data: () => ({
    prevRoute: "",
    explainerPopUp: false,
    noDataText: "No Data Available",
    totalDocs: 0,
    summary: {
      administered: 0,
      current: 0,
      discarded: 0,
      dosage: "unit",
      received: 0,
    },
    currentPage: 1,
    updatePopUp: false,
    userId: "",
    searchInputString: "",
    addRecordPopUp: false,
    organizationId: "",
    selectedRecord: {},
    drugItems: [],
    clinicsList: [],
    filteredProducts: [],
    customMonths: [],
    months:[
      { text: "January", value: "01" },
      { text: "February", value: "02" },
      { text: "March", value: "03" },
      { text: "April", value: "04" },
      { text: "May", value: "05" },
      { text: "June", value: "06" },
      { text: "July", value: "07" },
      { text: "August", value: "08" },
      { text: "September", value: "09" },
      { text: "October", value: "10" },
      { text: "November", value: "11" },
      { text: "December", value: "12" },
    ],
    // customYears: [],
    firstEntryYear: 0,
    discardRecordPopup: false,
    selectedMonth: "",
    selectedYear: "",
    selectedClinic: "",
    selectedDrug: "",
    selectedSortKey: "",
    selectedSortType: "",
    token: undefined,
    embedClinicId: undefined,
    dataTableParams: {
      search: "",
      sort: "",
      order: null,
      page: 1,
      limit: 100,
      selectedClinic: "",
      productId: "",
      month: "",
      year: "",
    },
    drugEntries: [],
    hasClinicNotSetup: false,
    filterDisabled: {
      month: true,
      drug: true,
      clinic: true,
      year: true,
    },
    clinicData: null,
    drugData: null,
    isMobileView: null,
    customProducts: [],
    addCustomRecordPopup: false,
    isOrgOwner: false,
    hasPrev: false,
    hasNext: false,
    hasDrugbookIncorrectBalance: false,
    drugbookIncorrectBalanceBannerURL: "#",
  }),
  methods: {
    ...mapActions("drugbook", [
      "fetchDrugEntries",
      "fetchDrugEntriesPDF",
      "getFirstEntryDate",
      "getAmountPerClinic",
      "getAssociatedClinics",
      "fetchCustomProducts",
    ]),
    ...mapActions("organization", ["fetchOrganizationAllClinics"]),
    ...mapActions("product", ["fetchProducts"]),
    ...mapActions("monthlyCompliance", [
      "downloadPdf",
    ]),
    ...mapActions("clinic", ["fetchClinicsDetail"]),
    ...mapMutations("admin",["SET_BEARER"]),
    handleSort(sortKey, sortType) {
      if (sortKey) {
        this.selectedSortKey = sortKey;
        this.selectedSortType = sortType;
      }
    },
    async genratePdfReport(){
      try {
        this.$vs.loading();
        const payload = {
          ...this.dataTableParams,
          isDrugbook: true,
        }
        const res = await this.fetchDrugEntriesPDF(payload);
        const clinic = _.find(this.clinicsList,['clinicId',this.dataTableParams.selectedClinic]).name
        const drug = _.find([...this.drugItems, ...this.customProducts],['_id',this.dataTableParams.productId]).name
        await this.DownloadPdf({
          url: res.data.data.Location,
          name: `${drug}-${this.months[parseInt(this.dataTableParams.month)-1].text}-${this.dataTableParams.year}-${clinic}`
        })
        this.$vs.loading.close()
      } catch (error) {
        this.$vs.loading.close()
        console.error("ERR: ", error);
      }
    },
    async generateCSVReport() {
      const clinic = _.find(this.clinicsList,['clinicId',this.dataTableParams.selectedClinic]).name;
      const drug = _.find([...this.drugItems, ...this.customProducts],['_id',this.dataTableParams.productId]).name;
      const csvName = `${drug}-${this.months[parseInt(this.dataTableParams.month)-1].text}-${this.dataTableParams.year}-${clinic}`;

      const csvColumns = [
        "Date",
        "Time",
        "Clinic",
        "Drug",
        "Area",
        "Patient",
        "Received",
        "Administered",
        "Discarded",
        "Balance",
        "Batch Number",
        "Received/Administered/Discarded by",
        ...(this.isNZRegion ? [] : ["Prescriber"]),
        "Notes"
      ];

      const csvData = [csvColumns.join(',')];

      this.drugEntries.map((entry) => {
        const prescriber = entry.prescriber.name ? entry.prescriber.name : entry.customPrescriber.name ? entry.customPrescriber.name : 'Fresh Clinics';
        const rowData = [
          moment(entry.entryDate).format("DD/MM/YYYY"),
          moment(entry.entryDate).format("hh:mm A"),
          clinic,
          entry.productInfo.name ? entry.productInfo.name : entry.customProduct.name ? entry.customProduct.name : '',
          entry.area ? entry.area : "",
          entry.patientName ? entry.patientName : "",
          this.getAmount("stocked", entry) || "",
          this.getAmount("used", entry) || "",
          this.getAmount("discarded", entry) || "",
          entry.balance ? parseFloat(entry.balance).toFixed(2) : "",
          entry.batchNumber ? entry.batchNumber : "",
          entry.performedBy ? entry.performedBy.name : "",
          ...(this.isNZRegion ? [] : [prescriber]),
          entry.notes ? entry.notes : "",
        ];

        const rowCSV = rowData.map(value => `"${value}"`).join(",");
        csvData.push(rowCSV);
      });

      const csvContent = csvData.join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, csvName);
    },
    async DownloadPdf({ url, name }) {
      const res = await this.downloadPdf(url);
      const blob = new Blob([res.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${name}-drugbook.pdf`;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    async fetchFirstEntryDate(clinicID) {
      if (clinicID) {
        try {
          this.$vs.loading();
          const res = await this.getFirstEntryDate({ clinicId: clinicID });
          if (res.data.data) {

            if (res.data.data.year == parseInt(this.selectedYear)){
                this.customMonths =[...this.months].filter(
                (e) =>
                    Math.round(parseInt(e.value)) >= res.data.data.month &&
                    (moment(new Date()).format("Y")> this.selectedYear || parseInt(e.value) <= parseInt(moment(new Date()).format("M")))
                );
              }
              else if (moment(new Date()).format("Y") > this.selectedYear ){
                this.customMonths =[...this.months]
              }
              else if (moment(new Date()).format("Y") == this.selectedYear ){
                this.customMonths =[...this.months].filter(
                (e) =>{
                  return(
                    parseInt(e.value) <= parseInt(moment(new Date()).format("M"))
                  )
                }
                );
              }

            /*this.customMonths =[...this.months].filter(
              (e) =>
                  Math.round(parseInt(e.value)) >= res.data.data.month &&
                  (moment(new Date()).format("Y")> this.selectedYear || e.value <= moment(new Date()).format("M"))
            );*/

            if (this.selectedMonth.length === 0 || !_.find(this.customMonths,['value',this.selectedMonth])) {
              if (parseInt(this.selectedYear) !==  parseInt(moment().format('YYYY')) ) {
                this.selectedMonth = this.customMonths[0].value;
              }
              else{
                this.selectedMonth = this.customMonths[this.customMonths.findIndex((element) => element.value === moment().format('MM'))].value;
              }
            }
            this.firstEntryYear = res.data.data.year;
          }
          this.$vs.loading.close();
        } catch (err) {
          console.error(err);
        }
      }
    },
    async fetchAmountPerClinic(clinicID, isClinicChange = true) {
      try {
        const res = await this.getAmountPerClinic({ clinicId: clinicID });
        const clinicsWithAmount = res.data.data
        .map((e) => {
          const id = e._id.product || e._id.custom
            return {
              text: [...this.drugItems, ...this.customProducts].find((item) => item._id === id).name,
              value: e._id.product || e._id.custom,
            };
          })
          .sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          );
        if (clinicsWithAmount && [...this.drugItems, ...this.customProducts].length > 0) {
          let temp = [...this.drugItems, ...this.customProducts]
            .filter((e) => {
              const fromOrg = !e.organizationId || e.organizationId === this.organizationId;
              return !clinicsWithAmount.some((item) => item.value === e._id) && fromOrg && e.isAvailable;
            })
            .map((e) => {
              return {
                text: e.name,
                custom: e.organizationId,
                value: e._id,
              };
            });
          let withBalance = [...clinicsWithAmount ].sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1);
          this.filteredProducts = [
            {
              title: "With Balance",
              group: withBalance,
            },
            {
              title: "Without Balance",
              group: [...temp],
            },
          ];
          const isProductInWithBalance = withBalance.some((e) => `${e.value}` === `${this.selectedDrug}`);
          const isProductInWihtoutBalance = [...temp].some((e) => `${e.value}` === `${this.selectedDrug}`);
          const isProductInList = isProductInWithBalance || isProductInWihtoutBalance;

          if (
            (isClinicChange || !isProductInList)  &&
            this.filteredProducts[0] &&
            this.filteredProducts[0].group[0]
            // this.selectedDrug.length < 1
          ) {
            this.selectedDrug = this.filteredProducts[0].group[0].value;
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    async getProductsList() {
      try {
        const res = await this.fetchProducts({
          search: this.search,
          limit: 1000,
        });
        this.drugItems = res.data.data.docs.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );
      } catch (error) {
        console.error(error.message);
      }
    },
    async getDrugEntries() {
      try {
        this.$vs.loading();
        this.dataTableParams["selectedClinic"] = this.selectedClinic;
        this.dataTableParams.productId = this.selectedDrug;
        this.dataTableParams.month = this.selectedMonth;
        this.dataTableParams.year = this.selectedYear;
        this.dataTableParams.sort = this.selectedSortKey;
        this.dataTableParams.order = this.selectedSortType;
        const { selectedClinic, productId, month, year } = this.dataTableParams;
        //Check if payload contains empty value
        if ([selectedClinic, productId, month, year].some(param => param === "")) return;
        const res = await this.fetchDrugEntries(this.dataTableParams);
        this.drugEntries = _.pullAll(
          res.data.data.docs.map((entries) => {
            if (entries.type === "stocked"  && entries.amount !== 0) {
              return entries;
            } else if (entries.type === "used"  && entries.amount !== 0) {
              return entries;
            } else if (entries.type === "discarded" && entries.amount !== 0) {
              return entries;
            }
            else if (entries.type === "adjustment" && entries.amount !== 0) {
              return entries;
            }
            return undefined;
          }),
          [undefined]
        );
        // this.totalDocs = res.data.data.pagination.total;
        this.hasPrev = res.data.data.hasPrev;
        this.hasNext = res.data.data.hasNext;
        this.summary = res.data.data.summary;
        this.fetchAmountPerClinic(this.selectedClinic, false);
        this.$vs.loading.close();
      } catch (e) {
        console.error(e);
      }
    },
    async fetchclinics() {
      try {
        const res = await this.getAssociatedClinics({
          userId: this.userId,
          orgId: this.organizationId,
        });
        // const res = await this.fetchOrganizationAllClinics(this.organizationId);
        this.clinicsList = res.data.data.filter(e => e.clinicName).map((item) => {
          return {
            clinicId: item._id,
            name: item.clinicName,
            clinicsCount: item.clinicsCount,
            drugEntries: item.drugEntries,
            hasDrugbookIncorrectBalance: item.hasDrugbookIncorrectBalance ? item.hasDrugbookIncorrectBalance : false,
            drugbookIncorrectBalanceBannerURL: item.drugbookIncorrectBalanceBannerURL
          };
        });


        if (
          this.clinicsList.length !== this.clinicsList[0].clinicsCount &&
          !this.hasClinicNotSetup &&
          // (!this.$route.path.includes("org-owner") && this.clinicsList.length !== this.clinicsList[0].clinicsCount )||
          // (this.$route.path.includes("org-owner") &&
          this.clinicsList.map((clinic) => clinic.drugEntries).includes(0)
          // )
        ) {
          this.hasClinicNotSetup = true;
        }
        if (
          this.clinicsList
            .map((clinic) => clinic.drugEntries)
            .reduce((total, number) => total + number, 0) === 0
        ) {
          this.redirectToLanding();
        } else {
          const firstNonNullPositiveEntry = _.find(this.clinicsList, obj => obj.drugEntries !== null && obj.drugEntries > 0);
        this.fetchFirstEntryDate(firstNonNullPositiveEntry.clinicId);
        this.fetchAmountPerClinic(firstNonNullPositiveEntry.clinicId);
          if (this.selectedClinic.length === 0) {
            // Check if selected defautl first clinic has drugentries ?
            this.selectedClinic = firstNonNullPositiveEntry.clinicId;
          } else {
            const inParams = this.selectedClinic;
            this.selectedClinic = "";
            // Check if selected defautl first clinic has drugentries ?
            setTimeout(() => {
              this.selectedClinic = inParams || this.clinicsList[0].clinicId;
            }, 100);
          }
          if (
            this.prevRoute.includes("/org-owner/drugbook/setup") ||
            this.prevRoute.includes("/nurse/drugbook/setup") ||
            this.prevRoute.includes("/doctor/drugbook/setup")
          ) {
            this.explainerPopUp = true;
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    redirectToSetup() {
      this.$router.push({ name: this.getRoleRouteName("Drugbook") });
    },
    redirectToLanding() {
      this.$router.push({ name: this.getRoleRouteName("DrugbookLanding") });
    },
    editRecord(record) {
      if(!(record.overriden || record.type === 'adjustment' || (record.performedBy._id !== this.userId && !this.isOrgOwner))){
        this.selectedRecord = record;
        this.updatePopUp = true;
      }
    },
    closeDisposalRecord() {
      this.discardRecordPopup = false;
      window.scrollTo(0, 0);
    },
    closeAddRecord() {
      this.addRecordPopUp = false;
    },
    closeAddCustomRecord() {
      this.addCustomRecordPopup = false;
    },
    closeUpdateRecord() {
      this.updatePopUp = false;
    },
    changeMonth() {
      this.$refs.monthSelect.focus();
      this.filterDisabled.month = false;
    },
    changeYear() {
      this.$refs.yearSelect.focus();
      this.filterDisabled.year = false;
    },
    changeDrug() {
      this.$refs.drugSelect.focus();
      this.filterDisabled.drug = false;
    },
    changeClinic() {
      this.$refs.clinicSelect.focus();
      this.filterDisabled.clinic = false;
    },
    filterCheck() {
      if (
        this.selectedMonth &&
        this.selectedYear &&
        this.selectedDrug &&
        this.selectedClinic
      ) {
        return true;
      }
      return false;
    },
    onAddStock() {
      if (this.filterCheck()) {
        this.addRecordPopUp = true;
      }
    },
    onDisposal() {
      if (this.filterCheck()) {
        this.discardRecordPopup = true;
      }
    },
    handleChangePage(page) {
      if (typeof page === 'string'){
        if (page === "next") {
            delete this.dataTableParams.prev;
            delete this.dataTableParams.id;

          this.dataTableParams.next = true;
          this.dataTableParams.id = this.drugEntries[this.drugEntries.length - 1]._id;
        }
        if (page === "prev") {
          delete this.dataTableParams.next;
          delete this.dataTableParams.id;

          this.dataTableParams.prev = true;
          this.dataTableParams.id = this.drugEntries[0]._id;
        }
        this.getDrugEntries();
      }
    },
    myEventHandler() {
      this.isMobileView = window.innerWidth < 768;
    },
    getAmount(type, item) {
      if (type === item.type) {
        return parseFloat(item.amount).toFixed(2);
      }
      return null;
    },
    async checkIsDrugBookEnabled() {
      let organizationId =''
      if(this.embedClinicId && this.embedClinicId.length>0){
        organizationId = await this.fetchOrgIdByClinic()
      }
      else if (
        localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
        )
      ) {
        organizationId = localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
        );
      }
      const { data } = await this.$store.dispatch(
        "organization/fetchOrganizationDetails",
        organizationId
      );
      const isDrugbookEnabled = data.data.isDrugbookEnabled;
      if (!isDrugbookEnabled) {
        this.$router.push({name: this.getRoleRouteName("notice-board")});
      }
    },
    async fetchAssociatedClinics(){
      try {
        await this.getAssociatedClinics({userId: this.userId, orgId: this.organizationId});
      } catch (error) {
        console.error(error)
      }
    },
    async fetchOrgIdByClinic(){
     const res = await this.fetchClinicsDetail(this.embedClinicId)
          const clinicsDetail = res.data.data
          const orgId = clinicsDetail.organizationDetail._id
          return orgId;
    },
    replaceQuery(querry){
      if(this.token){
        this.$router.replace({
            query: {
              ...querry,
              token: this.token,
              clinicId: this.embedClinicId
            }
        });
      }
      else{
        this.$router.replace({
            query: querry,
        });
      }
    },
    totalSummaryFormatted(num){
      const options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      };
      const formatted = Number(num).toLocaleString('en', options);
      return `${formatted} ${this.summary.dosage === "unit" ? "u" : this.summary.dosage}`
    },
    async getCustomProducts() {
      try {
        const res = await this.fetchCustomProducts(this.organizationId);
        this.customProducts = res.data.data
      } catch (error) {
        console.error("ERR: ", error);
      }
    },
  },
  computed: {
    customYears: function () {
      let self = this;
      let currentYear = moment(new Date()).format("YYYY");
      let years = [];
      for (let i = this.firstEntryYear; i <= currentYear; ++i) {
        years.push({
          text: i.toString(),
          value: i,
        });
      }
      if (this.selectedYear.length === 0) {
        self.selectedYear = years.reverse()[0].value;
      }
      return years.reverse();
    },
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
    toggleScreenSize: function () {
      if (this.isMobileView) {
        return !this.discardRecordPopup && !this.addRecordPopUp && !this.addCustomRecordPopup;
      }
      return true;
    },
    isNZRegion() {
      return process.env.VUE_APP_REGION === "NZ"
    },
    customSortByFunction() {
      return {
        sortOption: this.selectedSortKey,
        sortType: this.selectedSortType,
      }
    },
    drugItemsWithCustomProducts() {
      return [...this.drugItems, ...this.customProducts].sort((a, b) => a.name.localeCompare(b.name));
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from.fullPath;
    });
  },
  watch: {
    selectedYear: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchFirstEntryDate(this.selectedClinic)
        this.replaceQuery({
            selectedMonth: this.selectedMonth,
            selectedYear: this.selectedYear,
            selectedDrug: this.selectedDrug,
            selectedClinic: this.selectedClinic,
            selectedSortKey: this.selectedSortKey,
            selectedSortType: this.selectedSortType,
        });
      }
    },
    selectedMonth: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        let monthYear = newVal.split(" ");
        this.dataTableParams.month = monthYear[0];
        this.dataTableParams.year = monthYear[1];
        if (this.filterCheck()) {
          delete this.dataTableParams.prev;
          delete this.dataTableParams.next;
          delete this.dataTableParams.id;
          this.getDrugEntries();
        }
        this.replaceQuery({
            selectedMonth: this.selectedMonth,
            selectedYear: this.selectedYear,
            selectedDrug: this.selectedDrug,
            selectedClinic: this.selectedClinic,
            selectedSortKey: this.selectedSortKey,
            selectedSortType: this.selectedSortType,
        });
        this.filterDisabled.month = true;
      }
    },
    selectedDrug: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.filterCheck()) {
          delete this.dataTableParams.prev;
          delete this.dataTableParams.next;
          delete this.dataTableParams.id;
          this.getDrugEntries();
        }
        this.replaceQuery({
            selectedMonth: this.selectedMonth,
            selectedYear: this.selectedYear,
            selectedDrug: this.selectedDrug,
            selectedClinic: this.selectedClinic,
            selectedSortKey: this.selectedSortKey,
            selectedSortType: this.selectedSortType,
        });
        this.filterDisabled.drug = true;
        this.drugData = this.drugItems.filter((e) => e._id === newVal)[0];
      }
    },
    selectedClinic: function (newVal, oldVal) {
      if (newVal !== oldVal && newVal.length > 0) {
        this.fetchAmountPerClinic(newVal);
        if (this.filterCheck()) {
          delete this.dataTableParams.prev;
          delete this.dataTableParams.next;
          delete this.dataTableParams.id;
          this.getDrugEntries();
        }
        this.replaceQuery({
            selectedMonth: this.selectedMonth,
            selectedYear: this.selectedYear,
            selectedDrug: this.selectedDrug,
            selectedClinic: this.selectedClinic,
            selectedSortKey: this.selectedSortKey,
            selectedSortType: this.selectedSortType,
        });
        this.filterDisabled.clinic = true;
        this.clinicData = this.clinicsList.filter(
          (e) => e.clinicId === newVal
        )[0];
        this.hasDrugbookIncorrectBalance = this.clinicData.hasDrugbookIncorrectBalance;
        this.drugbookIncorrectBalanceBannerURL = this.clinicData.drugbookIncorrectBalanceBannerURL;
      }
    },
    customSortByFunction: function(newVal, oldVal) {
      if (newVal.sortOption) {
        if (this.filterCheck()) {
          delete this.dataTableParams.prev;
          delete this.dataTableParams.next;
          delete this.dataTableParams.id;
          this.getDrugEntries();
        }
        this.replaceQuery({
            selectedMonth: this.selectedMonth,
            selectedYear: this.selectedYear,
            selectedDrug: this.selectedDrug,
            selectedClinic: this.selectedClinic,
            selectedSortKey: this.selectedSortKey,
            selectedSortType: this.selectedSortType,
        });
      }
    }
  },
  filters: {
    momentDate: function (date) {
      return moment(date).format("DD/MM/YYYY");
    },
    momentTime: function (date) {
      return moment(date).format("H:mm:ss");
    },
  },
  async mounted() {
    this.token = this.$route.query.token
    this.embedClinicId = this.$route.query.clinicId
    this.SET_BEARER(this.$route.query.token)
    if(this.token && this.token.length>0){
      this.userId = jwt_decode(this.token).id;
    }
    else{
      this.userId = JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      )._id;
      this.isOrgOwner =  localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`) === 'Org Owner';
    }
    if(this.embedClinicId && this.embedClinicId.length>0){
      this.organizationId = await this.fetchOrgIdByClinic()
    }
    else if (
      localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      )
    ) {
      this.organizationId = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      );
    }
    window.addEventListener("resize", this.myEventHandler);
    this.myEventHandler();
    this.selectedClinic = this.$route.query.selectedClinic || "";
    this.selectedMonth = this.$route.query.selectedMonth || "";
    this.selectedYear = this.$route.query.selectedYear || "";
    this.selectedDrug = this.$route.query.selectedDrug || "";
    this.selectedSortKey = this.$route.query.selectedSortKey || "";
    this.selectedSortType = this.$route.query.selectedSortType || null;

    this.getCustomProducts();
    this.fetchclinics();
    this.getProductsList();
    this.fetchAssociatedClinics();
    setTimeout(() => {
      this.getDrugEntries();
    }, 1500);
  },
  created() {
    setTimeout(() => {
      this.checkIsDrugBookEnabled();
    }, 1000);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },

};
</script>

<style lang="scss">
.circle {
  top: 10px;
  right: 10px;
}

.fieldContainer {
  padding-top: 12px;
  padding-bottom: 12px;
}

.dropdownMenu {
  width: 200px;
}

.con-vs-popup>.vs-popup {
  width: 450px;
  height: auto;
}

.vs-popup--content {
  margin: auto;
}

.filterContainer {
  border: 100px;
}

.customSelect .vs-select--input {
  border: none;
}

.customSelect .vs-select--label,
.vs-select--label.input-select-label-primary--active {
  margin-left: 5px;
  color: rgba(0, 0, 0, 0.6);
}

.customSelect .vs-select--input:disabled {
  opacity: 100;
  pointer-events: none;
  background-color: transparent;
}

#explainer .vs-popup {
  width: 600px;
  border-radius: 0px;
}

#explainer .vs-popup--content {
  width: 600px;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

#explainer .vs-popup .vs-popup--header {
  display: none;
}

.popUpCustom .vs-popup--content {
  overflow: auto;
}

.customBorder {
  border: 1px solid rgba(246, 246, 246, 1);
  border-radius: 7px;
}

.totalContainer {
  border-radius: 7px;
  background-color: rgba(185, 185, 185, 0.13);
}

.basic-text {
  color: rgba(7, 66, 48, 1) !important;
  font-size: 14px !important;
}

.no-filter-view {
  background-color: rgba(185, 185, 185, 0.13);
  padding: 5em 15em;
  display: flex;
  justify-content: center;
  text-align: center;
  min-height: 50vh;
  border-radius: 7px;

  P {
    color: rgba(7, 66, 48, 0.58);
    font-size: 1.125rem;
  }
}

.changeBtn {
  color: rgba(var(--vs-primary), 1);
  top: 8px;
}

.changeBtn.btnyear {
  left: 44px;
}

.changeBtn.btnmonth {
  left: 58px;
}

.changeBtn.btnclinic {
  left: 53px;
}

.changeBtn.btndrug {
  left: 50px;
}

.vs-select-group h4 {
  font-size: 1rem;
  font-weight: 600;
}

</style>
