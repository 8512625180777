<template>
  <div>
    <vs-card>
      <div
        class="flex items-center justify-between w-full"
        slot="header">
        <div class="flex items-center justify-between">
          <p class="font-semibold lg:text-lg md:text-lg text-base">
            Clinic Oversight Products
          </p>
        </div>
        <vs-button
          @click="handleSidebar(true)()"
          icon="add"
          type="border"
          class="lg:flex md:flex hidden"
        >
          Add oversight products
        </vs-button>
        <vs-button
          @click="handleSidebar(true)()"
          type="border"
          class="lg:hidden md:hidden block"
        >
          Add
        </vs-button>
      </div>
      <div class="w-full">
        <div class="product-box w-full m-auto overflow-auto">
          <div v-if="tags.length">
            <div
              v-for="(item, index) in tags"
              :key="index"
              class="overflow-hidden inline-block select-none"
            >
              <p
                class="product-box py-2 px-4 rounded-lg ml-2 flex items-center"
              >
                {{ item.name }}
                <vs-icon
                  @click="handleRemove(item)"
                  icon="close"
                  size="12px"
                  class="ml-2 cursor-pointer"
                >
                </vs-icon>
              </p>
            </div>
          </div>
          <div
            v-else
            class="text-center lg:text-lg md:text-lg text-base select-none"
          >
            Add an oversight product to get started
          </div>
        </div>
        <div v-if="tags.length" class="mt-2">
          <vs-button
            type="flat"
            color="danger"
            icon="close"
            @click="handleClearSelected"
            >Clear Selected Products</vs-button
          >
        </div>
      </div>
    </vs-card>

    <vs-sidebar
      class="sidebarx oversight-products relative overflow-auto"
      parent="body"
      position-right
      click-not-close
      v-model="isOpen"
    >
      <!-- HEADER -->
      <div class="header space-y-3">
        <p class="text-xl font-semibold px-4 pt-4">Oversight Products</p>
        <p class="px-4">
          Products that are checked mean this doctor has the authority
          to provide oversight for that product in our video consultations.
          Unchecking this means they will not have access to provide oversight,
          and will not be visible on the doctor calls list.
        </p>
        <vs-divider></vs-divider>
        <vs-input
          v-model="searchString"
          @input="searchProducts"
          placeholder="Search products"
          class="w-full pb-4 px-4"
        />
      </div>
      <div class="header pb-2 w-full">
        <div class="flex mx-4 space-x-2">
          <vs-button
            type="border"
            @click="selectAllProducts"
            class="mr-2 flex-grow"
          >
              Select All
          </vs-button>
          <vs-button
            type="border"
            @click="removeSelected"
            class="mr-2
            flex-grow"
          >
            Unselect All
          </vs-button>
        </div>
      </div>
      <!-- BODY -->
      <div class="scrollable-content">
        <div v-for="item in products" :key="item._id">
          <vs-checkbox
            class="custom-checkbox"
            v-model="selected"
            :disabled="item.hasOversightProduct"
            :vs-value="item._id"
          >
            <span
              :class="{ 'opacity-50 select-none': item.hasOversightProduct }"
            >
              {{ item.name }}
            </span>
          </vs-checkbox>
        </div>
      </div>
      <!-- FOOTER -->
      <div class="footer flex flex-col gap-3" slot="footer">
        <vs-button @click="handleSave" :disabled="isSaving">
          <vs-icon
            v-if="isSaving"
            icon="refresh"
            class="animate-spin"
          ></vs-icon>
          Save
        </vs-button>
        <vs-button type="border" @click.prevent="handleSidebar(false)()">
          Exit
        </vs-button>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import _ from "lodash"
import { mapActions } from "vuex";

export default {
  props: {
    clinicId: {
      type: String,
      required: true,
      default: () => ""
    },
    selectedProducts: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isOpen: false,
      isSaving: false,
      firstLoad: true,
      searchString: "",
      selected: [],
      tags: [],
      products: [],
      defaultProducts: [],
    }
  },
  methods: {
    ...mapActions("product", ["fetchClinicOversightProducts"]),
    handleSidebar(isOpen) {
      return () => {
        this.isOpen = isOpen
      }
    },
    selectAllProducts() {
      this.selected = this.products.map(({ _id }) => _id);
    },
    removeSelected() {
      this.selected = [];
    },
    handleSave(isClick = true) {
      this.isSaving = true;

      this.tags = [];

      if (this.selected.length) {
        this.tags = this.defaultProducts.filter(({ _id }) =>
          this.selected.includes(_id)
        );
      }

      if (this.searchString) {
        this.searchString = "";
        this.products = this.getOversightProducts()
      }

      this.isOpen = false;
      this.isSaving = false;

      if (isClick) {
        this.$emit('handleSave', this.selected)
      }
    },
    handleRemove(item) {
      const selected = this.selected.filter(
        (product) =>
          product !== this.products.find((p) => p.name === item.name)._id
      );
      this.tags = this.tags.filter(
        (product) => product._id !== item._id
      );

      this.$emit('handleSave', selected)
      this.selected = selected
    },
    handleClearSelected() {
      this.tags = [];
      this.selected = [];

      this.$emit('handleSave', [])
    },
    searchProducts: _.debounce(function (data) {
      this.searchString = data;
      this.getOversightProducts()
    }, 500),
    async getOversightProducts() {
      try {
        const { data } = await this.fetchClinicOversightProducts({
          clinicId: this.clinicId,
          search: this.searchString
        })

        this.products = data.data
        /**
         * iterate through the products and check if elements is in selectedProducts array
         * if no set hasOversightProduct to false
         */
        this.products.forEach((product) => {
          if (this.selectedProducts.includes(product._id)) {
            product.hasOversightProduct = true
          } else {
            product.hasOversightProduct = false
          }
        })

        if (this.firstLoad) this.defaultProducts = data.data;

        this.firstLoad = false;
      } catch (err) {
        console.error(err)
      }
    },
  },
  watch: {
    clinicId: async function(clinicId) {
      if (clinicId) await this.getOversightProducts()
    },
    selectedProducts: async function(selectedProducts) {
      if (this.clinicId) await this.getOversightProducts()

      if (selectedProducts.length) {
        this.selected = selectedProducts

        this.handleSave(false)
      }
    },
  },
}
</script>

<style lang="scss">
.vs-sidebar--background {
  z-index: 52000 !important;
}

.vs-sidebar.vs-sidebar-position-right {
  z-index: 53000;
}

.oversight-products .vs-sidebar-primary {
  display: flex;
  flex-direction: column;
  max-width: none;
}

.oversight-products .custom-checkbox .vs-checkbox {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.oversight-products .vs-sidebar--items {
  overflow: hidden !important;
}

.oversight-products .vs-sidebar--items .scrollable-content {
  overflow-y: auto;
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 8px;
  height: calc(100% - 257px);
}

.oversight-products .vs-sidebar--items .header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.oversight-products .vs-sidebar--footer .footer {
  background-color: white;
  padding: 16px;
  z-index: 10;
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.1);
}

.sidebarx {
  .vs-sidebar {
    max-width: 350px;

    &--items {
      overflow-y: auto;
      overflow-x: hidden !important;
    }
  }
}

.product-box {
  border: 1px solid #e0e0e0;
  padding: 15px 10px;
  border-radius: 0.5rem;

  p {
    color: #626262;
    background-color: #f0f0f0;
  }
}
</style>
